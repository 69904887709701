import { render, staticRenderFns } from "./hangxin.vue?vue&type=template&id=50cb9fb7&scoped=true"
import script from "./hangxin.vue?vue&type=script&lang=js"
export * from "./hangxin.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50cb9fb7",
  null
  
)

export default component.exports