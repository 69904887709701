<template>
  <div class="">
      <el-row class="filter-header" :gutter="20" :class="panel.search ? 'open': ''">
        <el-col>
          <el-date-picker
            v-model="create_time"
            type="datetimerange"
            :picker-options="pickerOptions"
            start-placeholder="开始日期时间"
            ange-separator="至"
            end-placeholder="结束日期时间"
            value-format="timestamp"
            align="left"
            @change="dateChange"
            placeholder="选择日期时间范围">
          </el-date-picker>
          <el-input class="input-m" placeholder="请输入集合名称" v-model="params.tableName" clearable @clear="operatornameSearch" @change="operatornameSearch()"></el-input>
          <el-input class="input-m" placeholder="请输入key" v-model="params.key_str" clearable @clear="operatornameSearch" @change="operatornameSearch()"></el-input>
          <el-input class="input-m" placeholder="请输入val" v-model="params.val_str" clearable @clear="operatornameSearch" @change="operatornameSearch()"></el-input>

        </el-col>
      </el-row>

    <el-table :data="logsList" style="width: 100%" border v-loading="loading">
      <!-- <el-table-column prop="_id" label="操作id"></el-table-column> -->
      <el-table-column prop="create_time" label="创建时间" width="200"></el-table-column>
      <el-table-column prop="rec_time" label="记录时间" width="200"></el-table-column>
      <el-table-column prop="order_id" label="订单ID"></el-table-column>
      <el-table-column prop="order_status" label="订单状态" ></el-table-column>
      <el-table-column prop="aftersales_bn" label="售后状态" ></el-table-column>
      <el-table-column prop="aftersales_status" label="售后状态" ></el-table-column>
      <el-table-column prop="distributor_name" label="店铺名称"></el-table-column>
      <el-table-column prop="item_bn" label="商品编号"></el-table-column>
      <el-table-column prop="is_can_sale" label="上下架"></el-table-column>
      <el-table-column prop="store" label="库存"></el-table-column>
      <el-table-column prop="freeze" label="冻结"></el-table-column>

    </el-table>
    <!--
    <div v-if="total_count > params.pageSize" class="content-left content-top-padded">
      <el-pagination
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :current-page.sync="params.page"
      :total="total_count" :page-size="params.pageSize">
      </el-pagination>
    </div>
    -->
    <div class="content-padded content-center">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page.sync="params.page"
        :page-sizes="[10, 20, 50]"
        :total="total_count"
        :page-size="params.pageSize">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import { Message } from 'element-ui'
  import { getCompanysMongoLogs } from '@/api/company'


  export default {
    data () {
      return {
        logsList: [],
        companyList: [],
        companyTotal: 0,
        companyPageSize: 10,
        companyName: '',
        loading: false,
        total_count: 0,
        params: {
          page: 1,
          pageSize: 20,
          tableName: '',
          val_str: '',
          key_str: '',
        },

        time_start_begin: '',
        time_start_end: '',
        create_time: '',

        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const start = new Date();
              const end = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 89);
              end.setTime(end.getTime() - 3600 * 1000 * 24 * 0);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        panel: {
          search: false,
        },
        renderable: true
      }
    },
    methods: {
      handleCurrentChange (page_num) {
        this.params.page = page_num
        this.getDataList()
        },
      handleSizeChange(pageSize) {
        this.loading = false
        this.params.page = 1
        this.params.pageSize = pageSize
        this.getParams()
        this.getDataList()
      },
      initDate(){
        let start = new Date(), end = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24);
        end.setTime(end.getTime());
        this.create_time = [start, end];
        this.params.time_start_begin = start / 1000;
        this.params.time_start_end = end / 1000;
      },
      dateChange(val) {
        if (val && val.length > 0) {
          this.time_start_begin = val[0] / 1000;//this.dateStrToTimeStamp(val[0] + ' 00:00:00')
          this.time_start_end = val[1] / 1000;//this.dateStrToTimeStamp(val[1] + ' 23:59:59')
        } else {
          this.time_start_begin = ''
          this.time_start_end = ''
        }
        this.params.page = 1
        this.getParams()
        this.getDataList()

        // this.getOrders(this.params)
      },
      storeSearch(val) {
        val && val.shop_id
        this.params.distributor_id = val.shop_id
        this.params.page = 1
        this.getParams()
        this.getDataList()
      },

      operatornameSearch(e) {
        this.params.page = 1
        this.getParams()
        this.getDataList()

        // this.getOrders(this.params)
      },
      getParams() {
        this.params.time_start_begin = this.time_start_begin
        this.params.time_start_end = this.time_start_end
        // this.params.order_type = this.order_type
        // this.params.order_class = this.order_class
        // this.params.salesman_mobile = this.salesman_mobile
        // this.params.shop_code = this.shop_code
        // if (this.identifier.length == 11) {
        //   this.params.mobile = this.identifier
        //   this.params.order_id = ''
        // } else {
        //   this.params.mobile = ''
        //   this.params.order_id = this.identifier
        // }
      },
      getDataList () {
        this.loading = true
        getCompanysMongoLogs(this.params).then(response => {
          this.logsList = response.list
          this.total_count = response.total_count
          this.loading = false
        })
      }

    },
    mounted () {
      this.initDate();
      this.getDataList();
    }
  }
</script>
<style>
  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    img {
      width: 90%;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 10px;
    img {
      width: 90%;
    }
  }
  .row-bg {
    padding: 10px 20px;
    background-color: #f9fafc;
  }
  .service-label .el-checkbox:first-child{
    margin-left: 15px;
  }
  .service-label .el-input:first-child{
    margin-left: 15px;
  }
  .grid-detail {
    max-height: 300px;
    overflow-y: scroll;
    margin-bottom: 20px;
  }
  .el-carousel {
    width: 375px;
  }
</style>
<style>
  .grid-detail {
    table, .detail-content-wrap, .detail-content-item {
      width: 100%!important;
    }
    img {
      width: 100%;
    }
  }
  .grid-attribute {
    table {
      width: 100%!important;
    }
  }
</style>
