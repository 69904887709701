<template>
  <div>
    <SpFinder
      ref="finder"
      noSelection
      :url="URL"
      :setting="setting"
      fixed-row-action
      :hooks="{
        beforeSearch
      }"
    />
  </div>
</template>
<script>
import { tableSetting } from './config'
import { SyncDataList } from './api'
export default {
  components: {
  },
  data () {
    return {
      URL: SyncDataList,
      // 商品选择器
      goodsVisible: false,
      setItemStatus: false,
      relItemsIds: [],
      relStore: {},
    }
  },
  computed: {
    setting() {
      return tableSetting(this)
    }
  },
  mounted() {
  },
  methods: {
    refresh() {
      this.$refs.finder.refresh()
    },
    beforeSearch (param) {
      if (param.pullTime) {
        param = {
          ...param,
          pullTime: undefined,
          start_time: param.pullTime[0],
          end_time: param.pullTime[1]
        }
      }
      return {
        ...param
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .search-field-value {
  width: auto !important;
  .el-date-editor--datetimerange {
    min-width: 380px !important;
  }
}
::v-deep .sp-finder .cell .sp-finder-actions__wrap {
  justify-content: center !important;
}

</style>
