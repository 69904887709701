import { render, staticRenderFns } from "./youshu.vue?vue&type=template&id=06c48bfa&scoped=true"
import script from "./youshu.vue?vue&type=script&lang=js"
export * from "./youshu.vue?vue&type=script&lang=js"
import style0 from "./youshu.vue?vue&type=style&index=0&id=06c48bfa&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06c48bfa",
  null
  
)

export default component.exports