
<template>

  <div v-loading="pageLoading" label-width="120px"  style="margin-left: 30px;width: 90%">
    <div >
      <el-input placeholder="请输入页面标题" v-model="Shortlink.title">
        <template slot="prepend">页面标题</template>
      </el-input>
    </div>
    <br>
    <div >
      <el-input placeholder="请输入页面路径及参数如：pages/index/index?id=123456" v-model="Shortlink.arguments">
        <template slot="prepend">路径及参数</template>

      </el-input>
    </div>
    <div class="template-common" style="margin: 30px 0">
      <el-radio-group v-model="Shortlink.type">
        <el-radio :label="1">临时链接【30天】</el-radio>
        <el-radio :label="2">永久链接</el-radio>
      </el-radio-group>
    </div>
    <div style="margin: 30px ">
      <el-button type="primary" @click="getShortLink">保存</el-button>
    </div>
    <el-input placeholder="请输入标题"
              v-model="params.path"
              style="width: 300px;margin-bottom: 15px">
      <el-button slot="append" icon="el-icon-search" @click="fetchPageList"></el-button>
    </el-input>
    <el-table :data="list"
              v-loading="loading"
              stripe height="297"
              border
              :row-class-name="tableRowClassName"
    >
      <el-table-column prop="is_expire" label="有效期" align="center" show-overflow-tooltip min-width="110">
        <template slot-scope="scope">
          <i class="el-icon-time" v-if="scope.row.is_expire === '1'"></i>
          <span style="margin-left: 10px" v-if="scope.row.is_expire === '1'">{{ scope.row.expire_time | datetime('yyyy-MM-dd hh:mm:ss') }}</span>
          <span v-else>永久有效</span>
        </template>
      </el-table-column>
      <!--              <el-table-column prop="path" label="小程序页面路径" align="center" show-overflow-tooltip min-width="80"></el-table-column>-->
      <el-table-column prop="query" label="标题" align="center" show-overflow-tooltip min-width="140">
        <template slot-scope="scope">
          {{ scope.row.path }}
        </template>
      </el-table-column>
      <el-table-column prop="query" label="页面路径及参数" align="center" show-overflow-tooltip min-width="140">
        <template slot-scope="scope">
          {{ scope.row.query }}
        </template>
      </el-table-column>
      <el-table-column prop="url_scheme" label="Short Link" align="center" show-overflow-tooltip min-width="150">
        <template slot-scope="scope">
          <el-link :underline="false" type="success"
                   v-if="!(scope.row.is_expire === '1' && scope.row.expire_time < (new Date()).getTime() / 1000)"
                   v-clipboard:copy="scope.row.url_scheme"
                   v-clipboard:success="onCopy">{{ scope.row.url_scheme }}
          </el-link>
          <el-link v-else :underline="false" type="info" title="Short Link 已过期">{{ scope.row.url_scheme }}</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="updated_at" label="生成时间" align="center" show-overflow-tooltip min-width="100"></el-table-column>
      <el-table-column label="操作" min-width="40" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-link icon="el-icon-document-copy" type="success" title="复制Short Link" size="mini" :underline="false"
                   v-if="!(scope.row.is_expire === '1' && scope.row.expire_time < (new Date()).getTime() / 1000)"
                   v-clipboard:copy="scope.row.url_scheme"
                   v-clipboard:success="onCopy"></el-link>
          <el-link icon="el-icon-delete" type="danger" title="删除" size="mini" :underline="false" @click="delPage(scope.row.id)"></el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="center">
      <div class="content-padded content-center">
        <el-pagination
          small
          background
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="params.page"
          :total="total_count"
          :pager-count="5"
          :page-size="params.pageSize"
          :page-sizes="[5, 10, 20, 50, 100]"
        >
        </el-pagination>
      </div>

    </el-row>
  </div>

</template>
<script>
import { getShortLink } from '@/api/company'
import {getUrlSchemeList , deleteUrlScheme} from '@/api/wechat'

export default {
  data () {
    return {
      Shortlink: {
        'type':1,
        'arguments':'',
        'title':'',
      },
      loading: false,
      list: [],
      total_count: 0,
      remnant: 0,
      selected: [],
      pageLoading: false,
      params: {
        page: 1,
        pageSize: 5,
        path: ''
      },
    }
  },
  computed: {
  },
  methods: {
    onCopy() {
      this.$message({
        message: '复制小程序 Shortlink 成功',
        type: 'success'
      })
    },
    typeChange (val) {
      this.Shortlink.type = val
    },
    descInput(){

      var txtVal = this.Shortlink.arguments.length;

      this.remnant =  txtVal;

    },

    handleSizeChange(pageSize) {
      this.params.pageSize = pageSize;
      this.fetchPageList();
    },
    handleCurrentChange(page_num) {
      this.params.page = page_num
      this.fetchPageList();
    },
    delPage(id) {
      this.$confirm('确认删除小程序当前 shortLink 吗？').then(_ => {
        deleteUrlScheme(id).then(res => {
          this.$message({type: 'success', message: '删除小程序 shortLink 成功！'})
          this.fetchPageList()
        })
      })
    },
    fetchPageList() {
      this.loading = true
      this.params.link_type = 'short'
      getUrlSchemeList(this.params).then(response => {
        if (response.data.data.list) {
          this.list = response.data.data.list;
          this.total_count = response.data.data.total_count
        }
        this.loading = false
      })
    },
    tableRowClassName({row}) {
      let table_style = '';
      if (row.is_expire === '1' && row.expire_time < (new Date()).getTime() / 1000) {
        table_style = 'warning-row';
      }
      return table_style;
    },
    getShortLink () {
      if (this.Shortlink.arguments == "") {
        this.$message({message:'页面参数不能为空', type: 'error'})
        return
      }
      if (this.Shortlink.title == "") {
        this.$message({message:'页面标题不能为空', type: 'error'})
        return
      }
      let params = {}
      params['title'] = this.Shortlink['title']
      params['arguments'] = this.Shortlink['arguments'].replace(/ /g, '&nbsp;')
      params['type'] = this.Shortlink['type']?this.Shortlink['type']:1;

      getShortLink (params).then(response => {
        if (response.data.data.success === true) {
          this.$alert(response.data.data.list.link, 'shortLink', {
            confirmButtonText: '确定',
            callback: action => {
              this.fetchPageList()
              this.Shortlink.title = ''
              this.Shortlink.arguments = ''
            }
          });
        } else {
          this.$message({type:'error', 'message':'获取失败，请检查参数设置！【'+response.data.data.message+"】"});
        }
      })
    }
  },
  mounted () {
    this.fetchPageList();
  }
}
</script>
