<template>
  <el-form ref="form" label-width="200px">
    <el-form-item label="是否开启企业微信">
      <el-switch
        v-model="form.show"
        active-color="#13ce66"
        inactive-color="#efefef"
        active-value="1"
        inactive-value="0">
      </el-switch>
      <br/>
    </el-form-item>
    <el-card>
      <el-form-item label="企业ID">
        <el-input v-model="form.corpid" style="width:300px"></el-input>
        <br/>
        <span class="frm-tips">企业ID，获取方式参考<a href="https://work.weixin.qq.com/api/doc#90000/90135/90665/corpid"
                                             target="_blank">术语说明-corpid</a></span>
      </el-form-item>
      <el-form-item label="小程序appid">
        <el-input v-model="form.agents.app.appid" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item label="小程序AgentId">
        <el-input v-model="form.agents.app.agent_id" style="width:300px"></el-input>
        <br/>
        <span class="frm-tips">企业微信的小程序agent_id，获取方式参考<a href="https://work.weixin.qq.com/api/doc#90000/90135/90665/agentid"
                                                    target="_blank">术语说明-agent_id</a></span>
      </el-form-item>
      <el-form-item label="小程序Secret">
        <el-input v-model="form.agents.app.secret" style="width:300px"></el-input>
        <br/>
        <span class="frm-tips">企业微信的小程序凭证密钥，获取方式参考<a href="https://work.weixin.qq.com/api/doc#90000/90135/90665/secret"
                                                     target="_blank">术语说明-secret</a></span>
      </el-form-item>
      <el-form-item label="小程序Token">
        <el-input v-model="form.agents.app.token" style="width:300px"></el-input>
        <br/>
        <span class="frm-tips"><a href="https://work.weixin.qq.com/api/doc#90000/90135/90930"
                                                     target="_blank">小程序Token</a></span>
      </el-form-item>
      <el-form-item label="小程序EncodingAESKey">
        <el-input v-model="form.agents.app.aes_key" style="width:300px"></el-input>
        <br/>
        <span class="frm-tips"><a href="https://work.weixin.qq.com/api/doc#90000/90135/90930"
                                                     target="_blank">小程序EncodingAESKey</a></span>
      </el-form-item>
    </el-card>
    <el-card>
      <el-form-item label="客户联系Secret">
        <el-input v-model="form.agents.customer.secret" style="width:300px"></el-input>
        <br/>
        <span class="frm-tips">企业微信的客户联系凭证密钥，获取方式参考<a href="https://work.weixin.qq.com/api/doc#90000/90135/90665/secret"
                                                     target="_blank">术语说明-secret</a></span>
      </el-form-item>
      <el-form-item label="客户联系Token">
        <el-input v-model="form.agents.customer.token" style="width:300px"></el-input>
        <br/>
        <span class="frm-tips"><a href="https://work.weixin.qq.com/api/doc#90000/90135/91440"
                                  target="_blank">客户联系Token</a></span>
      </el-form-item>
      <el-form-item label="客户联系EncodingAESKey">
        <el-input v-model="form.agents.customer.aes_key" style="width:300px"></el-input>
        <br/>
        <span class="frm-tips"><a href="https://work.weixin.qq.com/api/doc#90000/90135/91440"
                                  target="_blank">客户联系EncodingAESKey</a></span>
      </el-form-item>
    </el-card>
    <el-card>
      <el-form-item label="通讯录Secret">
        <el-input v-model="form.agents.report.secret" style="width:300px"></el-input>
        <br/>
        <span class="frm-tips">企业微信的通讯录证密钥，<a href="https://work.weixin.qq.com/api/doc#90000/90135/90221"
                                                     target="_blank">获取方式</a></span>
      </el-form-item>
      <el-form-item label="通讯录Token">
        <el-input v-model="form.agents.report.token" style="width:300px"></el-input>
        <br/>
        <span class="frm-tips"><a href="https://work.weixin.qq.com/api/doc#90000/90135/90966"
                                  target="_blank">通讯录Token</a></span>
      </el-form-item>
      <el-form-item label="通讯录EncodingAESKey">
        <el-input v-model="form.agents.report.aes_key" style="width:300px"></el-input>
        <br/>
        <span class="frm-tips"><a href="https://work.weixin.qq.com/api/doc#90000/90135/90966"
                                  target="_blank">通讯录EncodingAESKey</a></span>
      </el-form-item>
      <div class="section-footer with-border content-center">
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </div>
    </el-card>
  </el-form>
</template>
<script>
  import {getWorkWechatConfig, setWorkWechatConfig} from '@/api/wechat'

  export default {
    data() {
      return {
        form: {
          show: 0,
          corpid: '',
          agents: {
            app: {
              appid: '',
              agent_id: '',
              secret: '',
              token: '',
              aes_key: '',
            },
            customer: {
              secret: '',
              token: '',
              aes_key: '',
            },
            report: {
              secret: '',
              token: '',
              aes_key: '',
            }
          },
        }
      }
    },
    methods: {
      getConfig() {
        getWorkWechatConfig().then(response => {
          this.form = response.data.data
        })
      },
      onSubmit() {
        setWorkWechatConfig(this.form).then(response => {
          this.form = response.data.data
          this.$message({
            type: 'success',
            message: '保存成功'
          })
        })
      },
    },
    mounted() {
      this.getConfig()
    }
  }
</script>
<style scoped lang="scss">
</style>
