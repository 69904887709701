/* 下拉列表请求 */
import { isEmpty } from '@/utils'
import store from '@/store'


export var websocket = {
  props: {
  },
  data () {
    return {
      websock: {},
    }
  },
  methods: {
    //初始化weosocket
    initWebSocket() {
      console.log(this.gws.ws.readyState, 'readyState')
      if (this.gws.ws.readyState === 1) { // 初始化时，关闭其他连接
        this.gws.ws.close()
      }
      let that = this
      if (typeof WebSocket === undefined) {
        this.$message.error('当前浏览器不支持订单通知')
        return
      }
      const jwt_token = store.getters.token
      const wsuri = process.env.VUE_APP_WEBSOCKET_API + "?token=" + jwt_token;
      that.ws = new WebSocket(wsuri);
      that.gws.setWs(that.ws)
      that.gws.ws.onmessage = this.websocketonmessage;
      that.gws.ws.onopen = this.websocketonopen;
      that.gws.ws.onerror = this.websocketonerror;
      that.gws.ws.onclose = this.websocketclose;
    },
    websocketonopen() { //连接建立之后执行send方法发送数据
      console.log('订单通知已连接', this.gws.ws.readyState)
      this.$store.dispatch('setGolderColor', '#67C23A')

      let _this = this
      clearInterval(this.heartbeatTimeout)
      this.heartbeatTimeout = setInterval(function () {
        _this.websocketsend(JSON.stringify({'type': 'ping'}));
      }, 30000);
    },
    websocketonerror(e) { //连接建立失败重连
      console.log(this.gws.ws, "gws")
      this.closeWebsocket()
      console.log(e, 'e')
    },
    websocketonmessage(e) { //数据接收
      console.log('数据接收', this.gws.ws.readyState)
      const redata = JSON.parse(e.data);
      if (redata && redata.type === 'neworder') {
        // this.newOrderList.push(redata);
        this.newOrderList.unshift(redata);
        // this.audioPlay()
        const audioDom = document.querySelector('#socketAudio')
        if (audioDom) {
          audioDom.remove()
        }
        const audio = document.createElement('audio') //生成一个audio元素
        audio.controls = true //这样控件才能显示出来
        audio.id = 'socketAudio'
        audio.style.position = 'fixed'
        audio.style.top = '-10000px'
        audio.src = 'https://cdn-image.tjtjshengtu.com/orderfilemp3/orderCreateNotice.mp3' //音乐的路径
        document.body.appendChild(audio) //把它添加到页面中
        const promise = audio.play();
        if (promise !== undefined) {
          promise.then(res => {
            // 播放成功
            console.log('播放成功')
          }).catch(error => {
            // 提醒用户点击激活播放，并查看新订单
            console.log('提醒用户点击激活播放，并查看新订单')
          });
        }
      } else if (redata && redata.type === 'no_delivery') {
        this.newOrderList.unshift(redata);
        // this.audioPlay()
        const audioDom = document.querySelector('#socketAudio')
        if (audioDom) {
          audioDom.remove()
        }
        const audio = document.createElement('audio') //生成一个audio元素
        audio.controls = true //这样控件才能显示出来
        audio.id = 'socketAudio'
        audio.style.position = 'fixed'
        audio.style.top = '-10000px'
        audio.src = 'https://cdn-image.tjtjshengtu.com/orderfilemp3/orderDeliveryError.MP3' //音乐的路径
        document.body.appendChild(audio) //把它添加到页面中
        const promise = audio.play();
        if (promise !== undefined) {
          promise.then(res => {
            // 播放成功
            console.log('播放成功')
          }).catch(error => {
            // 提醒用户点击激活播放，并查看新订单
            console.log('提醒用户点击激活播放，并查看新订单')
          });
        }
      } else if (redata && redata.type === 'order_lock') {
        this.newOrderList.unshift(redata);
        // this.audioPlay()
        const audioDom = document.querySelector('#socketAudio')
        if (audioDom) {
          audioDom.remove()
        }
        const audio = document.createElement('audio') //生成一个audio元素
        audio.controls = true //这样控件才能显示出来
        audio.id = 'socketAudio'
        audio.style.position = 'fixed'
        audio.style.top = '-10000px'
        audio.src = 'https://cdn-image.tjtjshengtu.com/orderfilemp3/ecpp_lock.MP3' //音乐的路径
        document.body.appendChild(audio) //把它添加到页面中
        const promise = audio.play();
        if (promise !== undefined) {
          promise.then(res => {
            // 播放成功
            console.log('播放成功')
          }).catch(error => {
            // 提醒用户点击激活播放，并查看新订单
            console.log('提醒用户点击激活播放，并查看新订单')
          });
        }
      }
    },
    websocketsend(Data) { //数据发送
      if (this.gws.ws.readyState == 1) {
        this.gws.ws.send(Data);
      }
    },
    websocketclose(e) { //关闭
      console.log(e, 'e')
      console.log('订单通知已断开')
      this.$store.dispatch('setGolderColor', '#E6A23C')
      clearInterval(this.heartbeatTimeout)
      if (e.code == 1006 && !e.wasClean) {
        this.initWebSocket()
      }
    },

    //关闭websocket
    closeWebsocket() {
      if (!isEmpty(this.gws.ws)) {
        this.gws.ws.close(3006)
      }
    },
  }
}
