import fetch from '@/utils/fetch'

export const SyncDataList = '/sensors/setting/sync_data/list'

export const SyncDataDetailList = '/sensors/setting/sync_data_rel/list'

// 批量同步枚举地址
export const BAT_SYNC_URL_ENUM = {
  item: '/goods/items?item_type=normal',
  coupon: '/discountcard/list',
  activity: '/offlineactivity/list',
}

/** 添加同步记录 */
export function syncDataCreate (data) {
  return fetch({
    url: '/sensors/setting/sync_data/create',
    method: 'post',
    data
  })
}

/** 同步记录重推 */
export function syncDataRetry (sync_id) {
  return fetch({
    url: '/sensors/setting/sync_data/retry',
    method: 'post',
    data: {
      sync_id
    }
  })
}
