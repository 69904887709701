<template>
  <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
    <el-tab-pane label="" name="weburl">
      <el-form ref="form" label-width="100px">
        <el-form-item label="新机设置">
          <el-input v-model="form.serviceH5Shezhifuwu"></el-input>
        </el-form-item>
        <el-form-item label="维修售后">
          <el-input v-model="form.serviceH5Weixiufuwu"></el-input>
        </el-form-item>
        
        <el-form-item label="公开课">
          <el-input v-model="form.serviceH5Classhour"></el-input>
        </el-form-item>

        <el-form-item label="私教">
          <el-input v-model="form.serviceH5Coach"></el-input>
        </el-form-item>

        <el-form-item label="私教01">
          <el-input v-model="form.serviceH5CoachiPhone"></el-input>
        </el-form-item>
        <el-form-item label="私教02">
          <el-input v-model="form.serviceH5CoachiPad"></el-input>
        </el-form-item>
        <el-form-item label="私教03">
          <el-input v-model="form.serviceH5CoachMac"></el-input>
        </el-form-item>
        <el-form-item label="私教04">
          <el-input v-model="form.serviceH5CoachWatch"></el-input>
        </el-form-item>

        <el-form-item label="活动路径-01">
          <el-input v-model="form.serviceH5active01"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-02">
          <el-input v-model="form.serviceH5active02"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-03">
          <el-input v-model="form.serviceH5active03"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-04">
          <el-input v-model="form.serviceH5active04"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-05">
          <el-input v-model="form.serviceH5active05"></el-input>
        </el-form-item>

        <el-form-item label="活动路径-06">
          <el-input v-model="form.serviceH5active06"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-07">
          <el-input v-model="form.serviceH5active07"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-08">
          <el-input v-model="form.serviceH5active08"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-09">
          <el-input v-model="form.serviceH5active09"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-10">
          <el-input v-model="form.serviceH5active10"></el-input>
        </el-form-item>
        
        <el-form-item label="活动路径-11">
          <el-input v-model="form.serviceH5active11"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-12">
          <el-input v-model="form.serviceH5active12"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-13">
          <el-input v-model="form.serviceH5active13"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-14">
          <el-input v-model="form.serviceH5active14"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-15">
          <el-input v-model="form.serviceH5active15"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-16">
          <el-input v-model="form.serviceH5active16"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-17">
          <el-input v-model="form.serviceH5active17"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-18">
          <el-input v-model="form.serviceH5active18"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-19">
          <el-input v-model="form.serviceH5active19"></el-input>
        </el-form-item>
        <el-form-item label="活动路径-20">
          <el-input v-model="form.serviceH5active20"></el-input>
        </el-form-item>
        

        <!--<el-form-item label="售后申请">
          <el-input v-model="form.aftersales"></el-input>
        </el-form-item>-->
        <!--<el-form-item label="我的已约">
          <el-input v-model="form.arranged"></el-input>
        </el-form-item>-->

        <div class="section-footer with-border content-center">
          <el-button type="primary" v-loading="loading" @click="onSubmit">保存</el-button>
        </div>
      </el-form>
    </el-tab-pane>
  </el-tabs>
</template>
<script>
  import { getWebUrlSetting, saveWebUrlSetting } from '@/api/company'
  export default {
    data () {
      return {
        activeName: 'weburl',
        loading: false,
        form: {
          mycoach: '',
          // aftersales: '',
          classhour: '',
          // arranged: '',
          shezhifuwu: '',
          weixiufuwu: ''
        }
      }
    },
    methods: {
      handleClick () {
        this.getConfig()
      },
      getConfig () {
        getWebUrlSetting().then(response => {
          this.form = response.data.data
        })
      },
      onSubmit () {
        this.loading = true
        saveWebUrlSetting(this.form).then(response => {
          this.$message({
            type: 'success',
            message: '配置成功'
          })
          this.loading = false
        })
          .catch(error => {
            this.loading = false
          })
      }
    },
    mounted () {
      this.getConfig()
    }
  }
</script>
<style scoped lang="scss">
  .el-row {
    margin-bottom: 10px;
    .frm-tips {
      color: #FF0000;
    }
  }
</style>
