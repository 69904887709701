<template>
	<div v-loading="fullscreenLoading" element-loading-text="正在绑定授权信息">
  </div>
</template>
<script>
import { getWechatBind } from '../api/wechat'
export default {
  data () {
    return {
      fullscreenLoading: false
    }
  },
  mounted () {
		const homePath = this.path_prefixes ? `/${this.path_prefixes}` : '/'
    // 如果不是授权回调，则获取授权链接地址
    if (this.$router.history.current.query.auth_code) {
      this.fullscreenLoading = true
      var query = { auth_code: this.$router.history.current.query.auth_code, auth_type: this.$route.params.auth_type}
      getWechatBind(query).then(response => {
        this.$store.dispatch('setAuthorizer', true)
        this.fullscreenLoading = false
        this.$message({
          message: '绑定或更新授权信息成功',
          type: 'success',
          duration: 5 * 1000
        })
        if (query.auth_type == 'woa') {
          this.$router.push({path: homePath})
        } else {
          this.$router.push({path: this.matchInternalRoute('editauthorize'), query: { newBind: true, wxapp_id: response.data.data.authorizer_appid, nick_name:response.data.data.nick_name}})
        }
      })
      .catch(error => {
        console.log(error)
        this.fullscreenLoading = false
        this.$message({
          type: 'error',
          message: '绑定失败'
        })
        if (query.auth_type == 'woa') {
          this.$router.push({path: homePath, query: { isBindFail: true }})
        } else {
          this.$router.push({path: this.matchInternalRoute('editauthorize')})
        }
      })
    } else {
      this.$router.push({ path: homePath, query: { isBindFail: true }})
    }
  }
}

</script>
