
<template>

  <div v-loading="pageLoading" label-width="120px"  style="margin-left: 30px;width: 50%">
    <el-card class="box-card"   type="flex" justify="center">
      <el-alert  type="info" title="公告标题"   show-icon :closable="false" >

      </el-alert>
      <div >

        <el-input class="input" type="text"  maxlength="10" placeholder="输入公告标题" show-word-limit v-model="Announcement.title"></el-input>
      </div>
    </el-card>
    <el-card class="box-card"  type="flex" justify="center">
      <el-alert  type="info"   title="公告配置" show-icon :closable="false" >

      </el-alert>
      <div >

        <el-input class="input" type="textarea" :rows="6"
                  @input="this.descInput" maxlength="150" v-model="Announcement.content"></el-input>
        <span style="float:right;color:#999;">{{this.remnant}}/150</span>

      </div>
    </el-card>
    <div class="template-common" style="margin: 30px 0">

      <el-switch
        v-model="Announcement.announcement_status"
        active-color="#13ce66"
        inactive-color="#ff4949"
        active-value="1"
        inactive-value="2"
        active-text="开启"
        inactive-text="不开启">

      </el-switch>
    </div>



    <div style="margin: 30px ">
      <el-button type="primary" @click="setAnnouncement">保存</el-button>
    </div>
  </div>
</template>
<script>
import { getAnnouncement, setAnnouncement } from '@/api/company'

export default {
  data () {
    return {
      Announcement: {
        'announcement_status':true,

      },
      remnant: 0,
      selected: [],
      pageLoading: true,
      params: [],
    }
  },
  computed: {
  },
  methods: {
    descInput(){

      var txtVal = this.Announcement.content.length;

      this.remnant =  txtVal;

    },
    getAnnouncement () {
      getAnnouncement().then(response => {
        this.Announcement = {...response.data.data, content: response.data.data.content.replace(/&nbsp;/g, ' ')}
        this.remnant = this.Announcement.content.length;
        if (response.data.data.length >0) {


        } else {
          this.pageLoading = false
        }
      }).catch(error => {
        this.pageLoading = false
      })
    },
    setAnnouncement () {
      if (this.Announcement.content == "") {
        this.$message({message:'公告不能为空', type: 'error'})
        return
      }
      if (this.Announcement.title == "") {
        this.$message({message:'公告标题不能为空', type: 'error'})
        return
      }
      let params = {}
      params['title'] = this.Announcement['title']
      params['content'] = this.Announcement['content'].replace(/ /g, '&nbsp;')
      params['announcement_status'] = this.Announcement['announcement_status']?this.Announcement['announcement_status']:2;

      setAnnouncement(params).then(response => {
        if (response.data.data.success === true) {
          this.$message({type:'success', 'message':'保存成功'});
        } else {
          this.$message({type:'error', 'message':'保存失败'});
        }
      })
    }
  },
  mounted () {
    this.getAnnouncement();
  }
}
</script>
