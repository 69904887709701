<template>
  <div>
    <el-tabs @change="getMsgConfig" v-model="activeName" type="border-card">
      <el-tab-pane label="门店小时达配送时效计算" name="base">
        <el-form label-width="60px">
          <el-card class="box-card">
            <!-- <div slot="header" class="clearfix">
              <span>同城时效</span>
            </div> -->
            <div>
              <el-form-item label="">
                <div class="el-col-8">
                  <el-input placeholder="" v-model="form.bh_time" type="number">
                    <template slot="prepend">&nbsp;门店备货&nbsp;</template>
                    <template slot="append">分钟</template>
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="el-col-8">
                  <el-input placeholder="" v-model="form.de_time" type="number">
                    <template slot="prepend">&nbsp;配送冗余&nbsp;</template>
                    <template slot="append">分钟</template>
                  </el-input>
                </div>
              </el-form-item>
            </div>
          </el-card>
        </el-form>
       
      </el-tab-pane>

      <el-tab-pane label="上门回收时效配置" name="tradein">
        <el-form label-width="60px">
          <el-card class="box-card">
            <div>
              <el-form-item label="">
                <div class="el-col-7">
                  <el-input placeholder="" v-model="form.tradein_reservation_days" type="number">
                    <template slot="prepend">&nbsp;下单后&nbsp;</template>
                    <template slot="append">天内</template>
                  </el-input>
                </div>
                <div class="el-col-10" style="display: flex;margin-left: 50px;">
                  每天
                  <div style="margin:0px 20px">
                    <el-input placeholder="" v-model="form.tradein_reservation_start_clock" type="number">
                      <!-- <template slot="prepend">&nbsp;每天&nbsp;</template> -->
                      <template slot="append">点</template>
                    </el-input>
                  </div>
                  至
                  <div style="margin-left: 20px">
                    <el-input placeholder="" v-model="form.tradein_reservation_end_clock" type="number">
                      <!-- <template slot="prepend">&nbsp;至&nbsp;</template> -->
                      <template slot="append">点</template>
                    </el-input>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="el-col-7">
                  <el-input placeholder="" v-model="form.tradein_reservation_chunk" type="number">
                    <template slot="prepend">&nbsp;预约时段间隔&nbsp;</template>
                    <template slot="append">小时</template>
                  </el-input>
                </div>
              </el-form-item>
            </div>
          </el-card>
        </el-form>
       
      </el-tab-pane>

      <el-tab-pane label="门店营业期间小时达预约可选时段计算" name="shop">
        <el-form label-width="60px">
          <el-card class="box-card">
            <!-- <div slot="header" class="clearfix">
              <span>同城时效</span>
            </div> -->
            <div>
              <el-form-item label="">
                <div class="el-col-8">
                  <el-input placeholder="" v-model="form.hours_start_reserve" type="number">
                    <template slot="prepend">&nbsp;营业开始预留时长&nbsp;</template>
                    <template slot="append">分钟</template>
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="el-col-8">
                  <el-input placeholder="" v-model="form.hours_end_reserve" type="number">
                    <template slot="prepend">&nbsp;营业结束预留时长&nbsp;</template>
                    <template slot="append">分钟</template>
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="el-col-8">
                  <el-input placeholder="" v-model="form.reservation_chunk" type="number">
                    <template slot="prepend">&nbsp;预约时段间隔&nbsp;</template>
                    <template slot="append">小时</template>
                  </el-input>
                </div>
              </el-form-item>
            </div>
          </el-card>
        </el-form>
      
      </el-tab-pane>

      <el-tab-pane label="美团待骑手接单监控时长" name="mtqs">
        <el-form label-width="60px">
          <el-card class="box-card">
            <!-- <div slot="header" class="clearfix">
              <span>同城时效</span>
            </div> -->
            <div>
              <el-form-item label="">
                <div class="el-col-8">
                  <el-input placeholder="" v-model="form.waiting_rider" type="number">
                    <template slot="prepend">&nbsp;美团待骑手接单监控时长&nbsp;</template>
                    <template slot="append">分钟</template>
                  </el-input>
                </div>
              </el-form-item>
            </div>
          </el-card>
        </el-form>

      </el-tab-pane>

      <el-tab-pane label="门店小时达配送时效计算规则" name="time">
        <el-form label-width="60px">
          <el-card class="box-card">
            <!-- <div slot="header" class="clearfix">
              <span>同城时效</span>
            </div> -->
            <div>
              <el-form-item label="">
                <div class="el-col-8">
                  <el-input placeholder="" disabled v-model="form.least_delivery_time" type="number">
                    <template slot="prepend">&nbsp;3km以内送达时效&nbsp;</template>
                    <template slot="append">分钟</template>
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="el-col-8">
                  <el-input placeholder="" disabled v-model="form.add_delivery_time_per_1km" type="number">
                    <template slot="prepend">&nbsp;超过3km每增加1km增加时效&nbsp;</template>
                    <template slot="append">分钟</template>
                  </el-input>
                </div>
              </el-form-item>
            </div>
          </el-card>
        </el-form>
      </el-tab-pane>
      <!-- TODO ECPP配送信息配置-->
      <el-tab-pane label="ecpp配送信息" name="ecpp">
        <el-form label-width="60px">
          <el-card class="box-card">
            <div>
              <el-form-item label="">
                <div class="el-col-8">
                  <el-input placeholder="" v-model="form.warehouse_delivery_name" type="text">
                    <template slot="prepend">&nbsp;配送平台方&nbsp;</template>
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item label="">
                <div class="el-col-8">
                  <el-input placeholder="" v-model="form.warehouse_delivery_send_time" type="number">
                    <template slot="prepend">&nbsp;发货时效&nbsp;</template>
                    <template slot="append">小时内</template>
                  </el-input>
                </div>
              </el-form-item>
            </div>
          </el-card>
        </el-form>
      </el-tab-pane>

    </el-tabs>

    <div style="text-align: center; margin-top: 20px">
      <el-button type="primary" @click="saveTcSetting">保存配置</el-button>
    </div>
  </div>
</template>
<script>
import { saveTcSetting, getTcSetting } from '../../../api/common'

export default {
  components: {
  },
  data() {
    return {
      form: {
        bh_time: 15,
        de_time: 30,
        hours_start_reserve: 0,
        hours_end_reserve: 0,
        reservation_chunk: 0,
        waiting_rider: 0,
        least_delivery_time: 0,
        add_delivery_time_per_1km: 0,
        warehouse_delivery_name: '',
        warehouse_delivery_send_time: '',
        tradein_reservation_days: 0,
        tradein_reservation_start_clock: 0,
        tradein_reservation_end_clock: 0,
        tradein_reservation_chunk: 0,
      },
      activeName: 'base'
    }
  },
  methods: {
    getMsgConfig() {
      let params = { type: this.activeName }
    },

    saveTcSetting() {
      let post = {
        ...this.form
      }
      saveTcSetting(post).then(response => {
        if (response.data.data.status === true) {
          this.$message({ type: 'success', message: '保存成功' })
          this.getTcSetting()
        } else {
          this.$message({ type: 'error', message: '保存失败' })
        }
      })
    },
    getTcSetting() {
      getTcSetting().then(response => {
        this.form = response.data.data
      })
    }
  },
  mounted() {
    this.getTcSetting()
  }
}
</script>
<style scoped type="text/css" lang="scss">
.el-tab-pane {
  // min-height: 700px;
}

.message-content {
  button {
    margin-left: 20px;
  }
}

.message-count {
  margin: 0 5px 0 20px;
  font-size: 24px;
  color: #ff5000;
}

.message-template {
  .item {
    display: inline-block;
    width: 210px;
    margin: 0 10px;
    border: 1px solid #dfdfdf;

    &-title {
      padding: 10px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      cursor: pointer;

      i {
        float: right;
        margin-top: 4px;
      }
    }

    &-content {
      height: 140px;
      padding: 20px 10px;
      color: #333;
      font-size: 12px;
    }

    &-footer {
      // border-top: 1px solid #dfdfdf;
      padding: 10px 0;
      text-align: center;

      button {
        width: 50%;

        &:hover {
          border: 1px solid #c4c4c4;
          color: #1f2d3d;
        }
      }
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    }

    &.not-open-sms {
      .item-title {
        background-color: #FF4949;
      }
    }

    &.succ-open-sms {
      .item-title {
        background-color: #13CE66;
      }
    }
  }
}

.message-autograph {
  .el-input {
    width: 240px;
    margin-right: 10px;
  }

  span {
    margin: 0 10px;
  }

  button {
    width: 80px;
  }
}

.message-prompt {
  margin-top: 30px;
  padding-left: 20px;

  .prompt {
    &-title {
      margin-bottom: 10px;
      font-size: 18px;
      color: #333;

      span {
        border-left: 3px solid #20A0FF;
        padding-left: 10px;
      }
    }

    &-content {
      padding-left: 8px;
      line-height: 1.6;
      color: #666;

      .item-title {
        margin-bottom: 5px;
      }

      .item-content {
        padding-left: 14px;
        margin-bottom: 10px;
      }
    }
  }
}

.mb_15 {
  margin-bottom: 15px;
}

.span-tip {
  color: #8c939d;
  font-size: small;
}

.pics-box {
  overflow: hidden;

  .goodspic-wrap {
    float: left;
    margin-right: 5px;
    overflow: hidden;

    .goodspic {
      z-index: 1;
      position: relative;
      float: left;
      width: 120px;
      height: 120px;
      margin: 0 5px 10px;
      border: 1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }

      .goodspic-mask {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .2);
        cursor: pointer;

        &.on {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .iconfont {
          margin: 0 8px;
          font-size: 20px;
          color: #fff;
        }
      }
    }
  }

  .upload-box {
    display: inline-block;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;

    .iconfont {
      font-size: 30px;
      color: #ccc;
    }
  }
}
</style>
