import Vue from 'vue'
import Vuex from 'vuex'
import { Base64 } from 'js-base64';
import { install as apiInstall } from '@/api'
import { micrApp } from "@/utils/micr-app";
import { SpPickerPlugin } from '@/components/sp-picker'
import App from './App'
import store from './store'
import vuePlugins from './utils/vuePlugins'
import vueExtend from './utils/vueExtend'
import router from './router'
import fetch from './utils/fetch'
import gws from "./mixins/gws"
import { initFinder } from '@shopex/finder'
import '@shopex/finder/lib/finder.css'
import '@/style/common.scss'

const plugins = {
  SpPickerPlugin
}
initFinder(Vue, {
  fetchLibrary: fetch,
  context: {
    qs: false,
    globalHooks: {
      beforeQuery: (p) => {
        let params = {
          ...p,
          pageSize: p.pageSize,
          page: p.pageNum,
          finderId: 100
        }
        // delete params.pageSize
        delete params.pageNum
        return params
      },
      afterQuery: (response) => {
        const { status_code, message } = response.data.data
        if (status_code == 500) {
          return Vue.prototype.$message.error(message)
        } else {
          return {
            ...response.data.data,
            count: parseInt(response.data.data.total_count)
          }
        }
      }
    }
  }
})
const installComponent = (Vue) => {
  const baseContext = require.context('./components', true, /index(\.vue|\.js)$/)
  const components = {}
  function resloveModule(mod) {
    Object.keys(mod).forEach((key) => {
      mod[key].name && (components[mod[key].name] = mod[key])
    })
  }

  baseContext.keys().forEach((key) => {
    const mod = baseContext(key)
    resloveModule(mod)
  })

  Object.keys(components).forEach((key) => {
    const comp = components[key]
    Vue.component(comp.name, comp)
  })

  Object.keys(plugins).forEach((name) => {
    const plugin = plugins[name]
    Vue.use(plugin)
  })
}

installComponent(Vue)

micrApp.init()

Vue.config.productionTip = false
Vue.prototype.gws = gws

Vue.use(Base64)
Vue.use(Vuex)
Vue.use(apiInstall)
new Vue({
  router,
  store,
  mounted() {
    document.body.style.setProperty('--themeColor', '#409EFF')
    document.body.style.setProperty('--themeColorRgb', [64, 158, 255].join(','))
  },
  render: h => h(App)
}).$mount('#app')


