<template>
  <div>
    <el-tabs type="border-card"  @tab-click="handleClick">
      <el-tab-pane>
        <span slot="label"> <span style="color: #E6A23C"><i class="el-icon-truck"></i>顺丰操作</span></span>
        <el-tabs v-model="activeName_SF" type="border-card" @tab-click="handleClick" tabPosition="left" style="height: 600px;"  >
          <el-tab-pane label="顺丰正向签收" name="first">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>正向签收</span>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="80px"
                  >
                    <el-form-item label="订单号" style="height: 80px">
                      <el-input v-model="order_id" placeholder="请输订单号 如360111222333444"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" style="height: 80px">
                      <el-input v-model="remarks" placeholder="备注"></el-input>
                    </el-form-item>
                    <div class="section-footer with-border content-center">
                      <el-button type="primary" @click="saveOrderOprate('sf_forward','顺丰正向签收')">执行</el-button>
                    </div>
                  </el-form>
                </div>
              </el-card>
            </div>
          </el-tab-pane>


          <el-tab-pane label="顺丰逆向签收" name="second">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>逆向签收</span>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="80px"
                  >
                    <el-form-item label="订单号" style="height: 80px">
                      <el-input v-model="order_id" placeholder="请输订单号 如360111222333444"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" style="height: 80px">
                      <el-input v-model="remarks" placeholder="备注"></el-input>
                    </el-form-item>
                    <div class="section-footer with-border content-center">
                      <el-button type="primary" @click="saveOrderOprate('sf_reverse','顺丰逆向签收')">执行</el-button>
                    </div>

                  </el-form>
                </div>
              </el-card>
            </div>
          </el-tab-pane>
          <el-tab-pane label="模拟顺丰发货" name="third">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>发货【3002】</span>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="80px"
                  >
                    <el-form-item label="订单号" style="height: 80px">
                      <el-input v-model="order_id" placeholder="请输订单号 例：360111222333444"></el-input>
                    </el-form-item>
                    <el-form-item label="物流单号" style="height: 80px">
                      <el-input v-model="delivery_code" placeholder="若没有物流单号，请填写SF+时间戳，例：SF1695351823"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" style="height: 80px">
                      <el-input v-model="remarks" placeholder="备注"></el-input>
                    </el-form-item>
                    <div class="section-footer with-border content-center">
                      <el-button type="primary" @click="saveOrderOprate('sf_delivery','顺丰发货')">执行</el-button>
                    </div>
                  </el-form>
                </div>
              </el-card>
            </div>
          </el-tab-pane>

        </el-tabs>
      </el-tab-pane>

      <el-tab-pane>
        <span slot="label"> <span style="color: #E6A23C"><i class="el-icon-truck"></i>美团操作</span></span>
        <el-tabs v-model="activeName_MT" type="border-card" @tab-click="handleClick" tabPosition="left" style="height: 600px;"  >
          <el-tab-pane label="物流信息回调" name="mt">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>物流信息回调</span>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="80px"
                  >
                    <el-form-item label="订单号" style="height: 40px">
                      <el-input v-model="order_id" placeholder="请输订单号 如360111222333444"></el-input>
                    </el-form-item>
                    <el-form-item label="物流单号" style="height: 40px">
                      <el-input v-model="delivery_code" placeholder="请输运单号 如175486416894568"></el-input>
                    </el-form-item>
                    <el-form-item label="操作类型" style="height: 40px ;width:400px">
                      <template>
                        <el-select v-model="option" placeholder="请选择" @change="opChange">
                          <el-option
                            v-for="item in mt_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </template>
                    </el-form-item>
                    <el-form-item label="状态值" style="height: 40px" v-if="showOC">
                      <el-input v-model="onther_code" placeholder="状态值"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" style="height: 80px">
                      <el-input v-model="remarks" placeholder="备注"></el-input>
                    </el-form-item>
                    <div class="section-footer with-border content-center">
                      <el-button type="primary" @click="saveOrderOprate('mt_op','物流信息回调')">执行</el-button>
                    </div>
                  </el-form>
                </div>
              </el-card>
            </div>
          </el-tab-pane>
          <el-tab-pane label="美团接口设置" name="mtapi">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>美团接口设置</span>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="180px">
                    <el-form-item label="下单接口返回：" style="height: 40px">
                      <el-radio v-model="mt_api_create" label="sync">实时</el-radio>
                      <el-radio v-model="mt_api_create" label="async">异步</el-radio>
                    </el-form-item>
                    <el-form-item label="预下单接口请求：" style="height: 40px">
                      <el-radio v-model="mt_api_pre" label="api">美团</el-radio>
                      <el-radio v-model="mt_api_pre" label="system">系统计算</el-radio>
                    </el-form-item>
                    <div class="section-footer with-border content-center">
                      <el-button type="primary" @click="saveOrderOprate('mt_op_api','美团接口设置')">执行</el-button>
                    </div>
                  </el-form>
                </div>
              </el-card>
            </div>
          </el-tab-pane>


        </el-tabs>
      </el-tab-pane>



      <el-tab-pane>
        <span slot="label"><span style="color: #E6A23C"><i class="el-icon-monitor"></i> OMS操作</span></span>
        <el-tabs v-model="activeName_OMS" type="border-card" @tab-click="handleClick" tabPosition="left" style="height: 600px;"  >
          <el-tab-pane label="自提核销" name="third">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>自提核销</span>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="80px"
                  >

                    <el-form-item label="订单号" style="height: 80px">
                      <el-input v-model="order_id" placeholder="请输订单号 如360111222333444"></el-input>
                    </el-form-item>
                    <el-form-item label="备注" style="height: 80px">
                      <el-input v-model="remarks" placeholder="备注"></el-input>
                    </el-form-item>
                    <div class="section-footer with-border content-center">
                      <el-button type="primary" @click="saveOrderOprate('ziti_done','自提订单核销')">执行</el-button>
                    </div>
                  </el-form>
                </div>
              </el-card>
            </div>
          </el-tab-pane>
          <el-tab-pane label="OMS推送" name="fourth">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>OMS推送</span>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="80px"
                  >
                    <el-form-item label="订单号" style="height: 40px ;width:400px">
                      <el-input v-model="order_id" width="100px" placeholder="请输订单号 如: 360111222333444"></el-input>
                    </el-form-item>

                    <el-form-item label="操作类型" style="height: 40px ;width:400px">
                      <template>
                        <el-select v-model="option" placeholder="请选择">
                          <el-option
                            v-for="item in oms_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </template>
                    </el-form-item>
                    <el-form-item label="备注" style="height: 180px ;width:500px">
                      <el-input v-model="remarks" placeholder="备注"></el-input>
                    </el-form-item>
                    <div class="section-footer with-border content-center">
                      <el-button type="primary" @click="saveOrderOprate('oms_push','oms推送')">执行</el-button>
                    </div>

                  </el-form>
                </div>
              </el-card>
            </div>
          </el-tab-pane>
          <el-tab-pane label="OMS取消" name="fifth">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>OMS取消</span>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="80px"
                  >

                    <el-form-item label="订单号" style="height: 80px">
                      <el-input type="textarea" v-model="order_id" placeholder="请输订单号 多个用英文逗号分割，建议不超过十个"></el-input>
                    </el-form-item>
                    <el-form-item label="操作类型" style="height: 40px ;width:400px">

                      <template>
                        <el-tag type="warning" v-model="oms_options_cancel">订单取消推送</el-tag>
                      </template>
                    </el-form-item>

                    <el-form-item label="备注" style="height: 80px">
                      <el-input v-model="remarks" placeholder="备注"></el-input>
                    </el-form-item>
                    <div class="section-footer with-border content-center">
                      <el-button type="primary" @click="saveOrderOprate('oms_cancel','取消订单推送')">执行</el-button>
                    </div>


                  </el-form>
                </div>
              </el-card>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><span style="color: #E6A23C"><i class="el-icon-chat-round"></i> 企业微信操作</span></span>
        <el-tabs v-model="activeName_WECOM" type="border-card" @tab-click="handleClick" tabPosition="left" style="height: 600px;"  >
          <el-tab-pane label="基础商品推送" name="wecom_goods">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>商品推送</span>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="80px"
                  >
                    <el-form-item label="goods_id" style="height: 40px ;width:500px">
                      <el-input v-model="goods_id"  placeholder="请输goodsId"></el-input>
                    </el-form-item>
                    <el-form-item label="操作类型" style="height: 40px ;width:400px">
                      <template>
                        <el-select v-model="option" placeholder="请选择">
                          <el-option
                            v-for="item in wecom_goods_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </template>
                    </el-form-item>
                    <el-form-item label="备注" style="height: 80px">
                      <el-input v-model="remarks" placeholder="备注"></el-input>
                    </el-form-item>
                    <div class="section-footer with-border content-center">
                      <el-button type="primary" @click="saveOrderOprate('wecom_goods','企微基础推送')">执行</el-button>
                    </div>

                  </el-form>
                </div>
              </el-card>
            </div>
          </el-tab-pane>
          <el-tab-pane label="门店商品推送" name="wecom_shop_goods">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>门店商品推送</span>
                  <div class="content-right">
<!--                    <el-button class="content-right"  type="primary" slot="reference" icon="el-icon-s-grid" @click="CompletePushLz('items')" >一键同步商品</el-button>-->
<!--                    <el-button class="content-right" type="danger" slot="reference" icon="el-icon-receiving" @click="CompletePushLz('inventory')" >一键同步库存</el-button>-->
                    <el-button class="content-right" type="success" slot="reference" icon="el-icon-files" @click="CompletePushLz('state')" >一键同步上下架</el-button>
<!--                    <el-button class="content-right" type="warning" slot="reference" icon="el-icon-s-shop" @click="CompletePushLz('price')" >一键同步上价格</el-button>-->
                  </div>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="80px"
                  >
                    <el-form-item label="门店编号" style="height: 40px ;width:500px">
                      <el-input v-model="apple_id"  placeholder="请输AppleId">
                        <el-popover
                          slot="append"
                          placement="right"
                          width="500"
                          trigger="click"
                          v-model="shop_visible"
                        >
                          <el-card class="box-card" shadow="always" style="margin: -15px">
                            <div slot="header" class="clearfix">
                              <span>门店信息</span>
                            </div>
                            <div>
                              <el-form ref="form"  label-width="120px" size="mini" label-position="left" >
                                <el-form-item  class="order_line" label="门店AppleID：">
                                  <b>{{ shop.shop_code }}</b>
                                </el-form-item>
                                <el-form-item  class="order_line" label="门店名称：">
                                  <b>{{ shop.name }}</b>
                                </el-form-item>
                                <el-form-item  class="order_line" label="店铺地址：">
                                  <b>{{ shop.store_address }}</b>
                                </el-form-item>

                              </el-form>
                            </div>
                          </el-card>
                          <el-button slot="reference" icon="el-icon-view" @click="showShop" >查看门店信息</el-button>
                        </el-popover>
                      </el-input>
                    </el-form-item>
                    <el-form-item label="操作类型" style="height: 40px ;width:400px">
                      <template>
                        <el-select v-model="option" placeholder="请选择">
                          <el-option
                            v-for="item in wecom_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          </el-option>
                        </el-select>
                      </template>
                    </el-form-item>
                    <el-form-item label="备注" style="height: 80px">
                      <el-input v-model="remarks" placeholder="备注"></el-input>
                    </el-form-item>
                    <div class="section-footer with-border content-center">
                      <el-button type="primary" @click="saveOrderOprate('wecom_shop_goods','门店商品信息推送')">执行</el-button>
                    </div>

                  </el-form>
                </div>
              </el-card>
            </div>
          </el-tab-pane>

          <!--          <el-tab-pane label="企微订单推送" name="wecom_order">
                      <div class="section-white content-padded">
                        <el-card class="box-card">
                          <div slot="header" class="clearfix">
                            <span>企微订单推送</span>
                          </div>
                          <div class="text item">
                            <el-form
                              ref="form_content"
                              :model="form_content"
                              :rles="rules"
                              label-width="80px"
                            >
                              <el-form-item label="订单号" style="height: 40px ;width:400px">
                                <el-input v-model="order_id" width="100px" placeholder="请输订单号 如: 360111222333444"></el-input>
                              </el-form-item>

                              <el-form-item label="操作类型" style="height: 40px ;width:400px">
                                <template>
                                  <el-select v-model="option" placeholder="请选择">
                                    <el-option
                                      v-for="item in wecom_order_options"
                                      :key="item.value"
                                      :label="item.label"
                                      :value="item.value">
                                    </el-option>
                                  </el-select>
                                </template>
                              </el-form-item>
                              <div class="section-footer with-border content-center">
                                <el-button type="primary" @click="saveOrderOprate('wecom_order','企微订单推送')">执行</el-button>
                              </div>

                            </el-form>
                          </div>
                        </el-card>
                      </div>
                    </el-tab-pane>-->
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><span style="color: #E6A23C"><i class="el-icon-s-custom"></i> EC员工购</span></span>
        <el-tabs v-model="activeName_EC" type="border-card" @tab-click="handleClick" tabPosition="left" style="height: 600px;"  >
          <el-tab-pane label="绑定EC用户" name="bind">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>EC用户基础操作</span>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="80px"
                  >
                  <el-form-item label="ECID" style="height: 80px">
                    <el-input v-model="ecid" placeholder="请输入Ecid"></el-input>
                  </el-form-item>
                  <el-form-item label="操作类型" style="height: 40px ;width:400px">
                    <template>
                      <el-select v-model="option" placeholder="请选择">
                        <el-option
                          v-for="item in ec_options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-form-item>
                    <el-form-item label="备注" style="height: 80px">
                      <el-input v-model="remarks" placeholder="备注"></el-input>
                    </el-form-item>
                  <el-form-item label="" style="height: 80px">
                      <el-button type="warning"  size="small"  @click="saveOrderOprate('ec','EC绑定操作')">绑定</el-button>
                    </el-form-item>

                </el-form>
                </div>
            </el-card>
            </div>
          </el-tab-pane>
          <el-tab-pane label="优惠券结果推送" name="coupon_result">
            <div class="section-white content-padded">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span>优惠券结果推送</span>
                </div>
                <div class="text item">
                  <el-form
                    ref="form_content"
                    :model="form_content"
                    :rles="rules"
                    label-width="80px"
                  >
                  <el-form-item label="couponId" style="height: 80px">
                    <el-input v-model="couponId" placeholder="请输入couponId"></el-input>
                  </el-form-item>
                  <el-form-item label="发放结果" style="height: 40px ;width:400px">
                    <template>
                      <el-select v-model="option" placeholder="请选择">
                        <el-option
                          v-for="item in coupon_status_list"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </template>
                  </el-form-item>
                    <el-form-item label="原因" style="height: 80px">
                      <el-input v-model="remarks" placeholder="失败时，请填写"></el-input>
                    </el-form-item>
                  <el-form-item label="" style="height: 80px">
                      <el-button type="warning"  size="small"  @click="saveOrderOprate('ec_coupon','优惠券结果通知')">提交</el-button>
                    </el-form-item>

                </el-form>
                </div>
            </el-card>
            </div>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import {
  kefuToolPush,mtApiSetting
} from '../../../api/company'
/** 富文本编辑器：https://github.com/surmon-china/vue-quill-editor */
import { quillEditor, Quill } from 'vue-quill-editor'
import {getDistributorInfo} from '@/api/marketing'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
let fontSizeStyle = Quill.import('attributors/style/size')
fontSizeStyle.whitelist = ['10px', '11px', '12px', '13px', '14px', '15px', '16px', '17px', '18px', '19px', '20px', '22px', '24px', '26px', '28px', '30px', '32px', '36px', '38px', '40px', '45px', '50px', false]
Quill.register(fontSizeStyle, true)
// import './size-set.css'
export default {
  data () {
    return {
      mt_api_create:'sync',
      mt_api_pre:'api',
      shop_visible: false,
      shop:[],
      couponId:'',
      delivery_code:'',
      oms_options_cancel:'cancel',
      coupon_status:'',
      oms_options:[
        {
          value: 'omsorder',
          label: '订单创单推送'
        },
        {
          value: 'omspayed',
          label: '订单支付推送'
        },
        {
          value: 'omsdlycode',
          label: '订单物流单号'
        },
        {
          value: 'omsrec',
          label: '订单接单'
        },
        {
          value: 'omstoziti',
          label: '修改用户订单地址【转自提】'
        }
        // {
        //   value: 'oms3',
        //   label: '模拟订单过仓成功'
        // },
        // {
        //   value: 'oms10',
        //   label: '交易完成'
        // },
        // {
        //   value: 'oms51',
        //   label: '备货成功'
        // },
        // {
        //   value: 'oms53',
        //   label: '订单接单成功'
        // },
      ],
      mt_options:[
        {
          value: '0',
          label: '订单待调度'
        },
        {
          value: '20',
          label: '订单已接单'
        },
        {
          value: '30',
          label: '订单已取货'
        },
        {
          value: '50',
          label: '订单已送达'
        },
        {
          value: '99',
          label: '订单已取消'
        } ,
        {
          value: 'onther',
          label: '其他状态'
        }
        // {
        //   value: 'oms3',
        //   label: '模拟订单过仓成功'
        // },
        // {
        //   value: 'oms10',
        //   label: '交易完成'
        // },
        // {
        //   value: 'oms51',
        //   label: '备货成功'
        // },
        // {
        //   value: 'oms53',
        //   label: '订单接单成功'
        // },
      ],
      coupon_status_list:[
        {
          value: 1,
          label: '发送成功'
        },
        {
          value: 0,
          label: '发送失败'
        }
      ],
      wecom_options: [
        {
          value: 'shop_item',
          label: '同步门店商品'
        },
        {
          value: 'shop_item_status',
          label: '同步门店商品上下架'
        },
        {
          value: 'shop_price',
          label: '同步门店商品价格'
        },
        {
          value: 'shop_store',
          label: '同步门店商品库存'
        }
      ],
      wecom_goods_options: [
        {
          value: 'add_goods',
          label: '新增商品'
        },
        {
          value: 'del_goods',
          label: '删除商品'
        },
        {
          value: 'shop_item',
          label: '同步门店商品【by 商品】'
        }
      ],
      ec_options: [
        {
          value: 'bind',
          label: '绑定Ec用户'
        }
      ],
      wecom_order_options: [
        {
          value: 'order_create',
          label: '创单推送'
        },
      ],
      activeName: 'first',
      showOC: false,
      activeName_SF: 'first',
      activeName_MT: 'mt',
      activeName_OMS: 'third',
      activeName_WECOM: 'wecom_goods',
      activeName_EC: 'bind',
      option:'',
      order_id:'',
      apple_id:'',
      goods_id:'',
      ecid:'',
      remarks:'',
      onther_code:'',
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
              ['blockquote', 'code-block'], // 引用，代码块
              [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
              [{ list: 'ordered' }, { list: 'bullet' }], // 列表
              [{ script: 'sub' }, { script: 'super' }], // 上下标
              [{ indent: '-1' }, { indent: '+1' }], // 缩进
              [{ direction: 'rtl' }], // 文本方向
              [{ size: fontSizeStyle.whitelist }], // 字体大小  ["small", false, "large", "huge"]
              [{ header: [1, 2, 3, 4, 5, 6, false] }], // 几级标题
              [{ color: ['#000', '#333', '#666', '#929292', '#007AFF'] }, { background: [] }], // 字体颜色，字体背景颜色
              [{ align: [] }], // 对齐方式
              ['image'], ['clean'] // 上传图片、上传视频 //清除字体样式
            ]
          }
        }
      },

      rules: {
        content: [
          { required: true, message: '请输入备注', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    showShop () {
      this.shop_visible = false
      getDistributorInfo({ shop_code: this.apple_id }).then(
        response => {
          if (response.data.data.shop_code) {
            this.shop = response.data.data
            this.shop_visible = true
          } else {
            this.shop_visible = false
            this.$message.error('门店信息查询失败，请检查appleId')
          }
        }
      )
    },
    CompletePushLz(option){
      let complete={};
      complete.option = option
      complete.complete = option//标识是全量操作
      complete.type = 'wecom_shop_goods'
      this.$confirm(
        '<span style="color: #ff6547">当前操作耗时较长，请确保当前系统无其他繁重事物再进行！</span>>确认是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString:true,
          type: 'warning'
        }).then(() => {
        kefuToolPush(complete).then(response => {
          this.$message({
            message: response.data.data.msg?response.data.data.msg:'操作成功',
            type: 'success'
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      })
    },
    initMtAPi(){
      mtApiSetting().then(response => {
        this.mt_api_create = response.data.data.create
        this.mt_api_pre = response.data.data.pre
      })
    },
    saveOrderOprate(type, message) {
      let params = {
        "order_id": this.order_id,
        "apple_id": this.apple_id,
        "remarks": this.remarks,
        "option": this.option,
        "type": type,
        "onther_code": this.onther_code,
        'ecid':this.ecid,
        'goods_id':this.goods_id,
        'coupon_id':this.couponId,
        'delivery_code':this.delivery_code,
        'mt_api_create':this.mt_api_create,
        'mt_api_pre':this.mt_api_pre,
      }
      if (this.order_id === '' && type !== 'wecom_goods' && type !== 'wecom_shop_goods' && type !== 'ec'  && type !== 'ec_coupon' &&  type !== 'mt_op_api') {
        this.$message.error('请填写订单号')
        return
      }
      if ((type === 'oms_push' || type === 'wecom' || type === 'ec' || type === 'ec_coupon'  || type === 'mt_op' ) && this.option === '') {
        this.$message.error('请选择操作类型')
        return
      }
      if(this.option ==='onther' && this.onther_code === ''){
        this.$message.error('请填写状态值')

      }
      if ((type === 'wecom') && this.apple_id === '') {
        this.$message.error('请填写门店编号')
        return
      }
      if ((type === 'wecom_goods') && this.goods_id === '') {
        this.$message.error('请填写goodsId')
        return
      }
      let info = (type === 'wecom_shop_goods') ? '门店编号：' : '订单号：'
      let info_data = (type === 'wecom_shop_goods') ? this.apple_id : this.order_id
      if( type==='mt_op_api'){
        info =''
      }
      this.$confirm(
        '当前操作:<span style="color:#F56C6C;font-weight: bold;font-size: 16px;">'
        +message+'</span><br>' + info + '<span style="color:#F56C6C;font-weight: bold;font-size: 16px;">'
        +info_data
        +'</span><br>确认是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString:true,
          type: 'warning'
        }).then(() => {
        kefuToolPush(params).then(response => {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.initMtAPi()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      })


    },
    opChange(){
      if (this.option === 'onther') {
        this.showOC =true
      }else{
        this.showOC=false
      }
    },
    handleClick(){
      this.order_id = ''
      this.ecid = ''
      this.remarks = ''
      this.option = ''

    },

  },
  components: {
    quillEditor
  },
  mounted () {
    this.initMtAPi()
    this.private_getTemplateList()
    this.useterm_getTemplateList()
    this.getagreementinfofunc()
    // this.getMemberRegisterDistiSettingfunc()

  }
}
</script>
<style scoped type="text/css">
.setting-header {
  background: #ecf5ff;
}


</style>
