import { createSetting } from '@shopex/finder'
import { MessageBox } from 'element-ui'
import { useDialog } from '@shopex/finder'
import { syncDataRetry, SyncDataDetailList } from '../api'

const ITEM_TYPE_ENUM = {
  item: '商品',
  coupon: '优惠券',
  activity: '门店活动'
}
const SYNC_TYPE_EUNM = {
  auto: '自动同步',
  manual: '手动同步'
}
const ITEM_TYPE_OPTIONS = formatEnumToOptions(ITEM_TYPE_ENUM)

/**
 * 将枚举值格式化成下拉
 * @param {Object} obj 枚举对象
 * @return {Array} 格式化后的下拉数组
*/
function formatEnumToOptions (obj) {
  let options = []
  for (let key in obj) {
    options.push({
      label: obj[key],
      value: key
    })
  }
  return options
}

const commonActions = [
  { label: '取消', key: 'close', size: 'default' },
  { label: '确认同步', key: 'save', type: 'primary', size: 'default' }
]

export const tableSetting = (vm) => createSetting({
  search: [
    {
      name: '推送时间',
      key: 'pullTime',
      type: 'date-time-picker',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      startPlaceholder: '开始时间',
      endPlaceholder: '结束时间'
    },
    {
      name: '物品类型',
      key: 'item_type',
      type: 'select',
      options: ITEM_TYPE_OPTIONS
    },
  ],
  columns: [
    { name: '推送时间',
      key: 'sync_time',
      minWidth: 180,
    },
    { name: '物品名称', key: 'item_name', minWidth: 150 },
    { name: '物品ID', key: 'item_id', minWidth: 150 },
    { name: '物品类型',
      key: 'item_type',
      minWidth: 150,
      formatter: (v) => ITEM_TYPE_ENUM[v]
    },
    { name: '同步类型',
      key: 'sync_type',
      minWidth: 150,
      formatter: (v) => SYNC_TYPE_EUNM[v]
    },
  ],
  actions: [
    {
      name: '创建批量同步',
      type: 'dropdown',
      slot: 'header',
      buttonType: 'primary',
      dropdownItems: [
        {
          name: '商品',
          type: 'button',
          buttonType: 'default',
          action: {
            handler () {
              useDialog(null, {
                title: `选择商品`,
                width: '80%',
                component: () => import('../components/goods/index.vue'),
                actions: commonActions
              })
            }
          }
        },
        {
          name: '优惠券',
          type: 'button',
          buttonType: 'default',
          action: {
            handler () {
              useDialog(null, {
                title: `选择优惠券`,
                width: '80%',
                component: () => import('../components/discoupon/index.vue'),
                actions: commonActions
              })
            }
          }
        },
        {
          name: '门店活动',
          type: 'button',
          buttonType: 'default',
          action: {
            handler () {
              useDialog(null, {
                title: `选择门店活动`,
                width: '80%',
                component: () => import('../components/activity/index.vue'),
                actions: commonActions
              })
            }
          }
        }
      ]
    },
    {
      name: '重推',
      type: 'button',
      buttonType: 'text',
      action: {
        handler: (row) => {
          MessageBox.confirm('请确认是否重推？', '提示').then(async () => {
            const id = row[0].id
            await syncDataRetry(id)
            vm.$message.success('重推成功')
            vm.refresh()
          })
        }
      }
    },
    {
      name: '查看明细',
      type: 'button',
      buttonType: 'text',
      visible: (row) => {
        return row.sync_type === 'manual'
      },
      action: {
        handler: (row) => {
          let component = null
          const sync_id = row[0].id
          const url = `${SyncDataDetailList}?sync_id=${sync_id}`
          switch (row[0].item_type) {
            case 'item':
              component = () => import('../components/goods/index.vue')
              break;
            case 'coupon':
              component = () => import('../components/discoupon/index.vue')
              break;
            case 'activity':
              component = () => import('../components/activity/index.vue')
              break;
          }
          useDialog(null, {
            title: `查看明细`,
            width: '80%',
            url,
            component
          })
        }
      }
    }
  ]
})
