<template>
<div>
  <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
    <el-tab-pane label="小工具" name="weburl">
      <el-form ref="form" label-width="100px">

        <el-form-item label="操作">
          <el-input  placeholder="请输入 <接口> ： PushCreateOrder(订单)/PushTrade(支付单)/PushReceiveOrder（接单） " v-model="form.opCmd"></el-input>
              <div > <a>* 订单:        PushCreateOrder</a></div>
              <div > <a>* 支付:        PushTrade </a></div>
              <div > <a>* 接单:        PushReceiveOrder </a></div>

        </el-form-item>

        <el-form-item label="参数">
          <el-input type="textarea"
              :rows="10"
              placeholder="请输入 <参数> ：36600000000000（订单号） "
              style="width:650px"
              v-model="form.params">
              </el-input>

              <!-- <div class="red"> <a href="https://www.json.cn" target="_blank">Json格式工具：  https://www.json.cn  </a></div> -->

        </el-form-item>

        <div class="section-footer with-border content-center">
          <el-button type="primary" v-loading="loading" @click="onSubmit">执行</el-button>
        </div>

        </br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br>
        </br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br>

    <el-table :data="dataList" v-loading="loading" :height="wheight-150">
      <el-table-column type="expand">
        <template slot-scope="props">

          <el-collapse>
            <!-- <el-collapse-item title="接口" name="1" >
              {{props.row.name}}
            </el-collapse-item> -->
            <el-collapse-item title="参数" name="1">
              {{props.row.params}}
            </el-collapse-item>
            <el-collapse-item title="说明" name="2">
              {{props.row.com}}
            </el-collapse-item>
          </el-collapse>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="name"></el-table-column>
      <el-table-column prop="name" label="method"></el-table-column>
    </el-table>
      </br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br>
      </br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br></br>

      </el-form>
    </el-tab-pane>
  </el-tabs>


    </div>

</template>

<script>
  import {
    gettooluat,
    savetooluat
  } from '@/api/company'
  export default {
    data() {
      return {
        activeName: 'weburl',
        loading: false,
        activeNames: ['1'],
        dataList: [
           {
            title: '0 OMS推送',
            name: 'PushCreateOrder/PushReceiveOrder/confirmZitiDone',
            com: 'cmd : 创单/接单/核销',
            params: '{"storeCode": 123456,"bsOrderCode":357561200000000000} ',
           },
          {
            title: '1 运单生成',
            name: 'sforderimportback',
            com: 'sforderoppush',
            params: '{"customer_order_code":"360844400006831636_1636968801","supplier_order_ids":["TCSF101180000001"],"supplier_system":"BSPTC","order_id":"362174800008479070","company_id":1,"data_type":"SF_CODE_CALLBACK"}',
          },
           {
            title: '2 运单路由',
            name: 'sforderoppush',
            com: 'op_code: 6021-签收； 3002-揽收； reasonCode:"4"-拦截  ',
            params: '{"desc":"fahuo-test","op_code":"3002","op_name":"发货OK-签收OK-1","operator":"lijian","route_src":0,"delivery_corp":"SFTC","delivery_code":"SF1639465467","operator_time":1637215898,"operator_phone":"15089390000","supplier_order_id":"SF1639465467","order_id":"363560100010752040","customer_order_code":"363560100010752040","reasonCode":"0"}',
          },
           {
            title: '3 OMS状态',
            name: 'omsStatus',
            com: 'oms_status : 5-出库 51-备货完成 3-订单确认 ',
            params: '{"oms_status": 5,"order_id":357561200006540731}',
          },

          ],
        form: {
          mycoach: '',
          // aftersales: '',
          classhour: '',
          // arranged: '',
          shezhifuwu: '',
          weixiufuwu: ''
        }
      }
    },
    methods: {
      handleChange(val) {
        console.log(val);
      },

      handleClick() {
        this.getConfig()
      },
      getConfig() {
        gettooluat().then(response => {
          this.form = response.data.data
        })
      },
      onSubmit() {
        this.loading = true
        savetooluat(this.form).then(response => {
            this.$message({
              type: 'success',
              message: '执行成功！'
            })
            this.loading = false
          })
          .catch(error => {
            this.loading = false
          })
      }
    },
    mounted() {
      this.getConfig()
    }
  }
</script>

<style scoped lang="scss">
  .el-row {
    margin-bottom: 10px;
    .frm-tips {
      color: #FF0000;
    }
  }
</style>
