<template>
  <div>
    <el-row :gutter="20">
      <el-input
        class="input-b"
        clearable
        placeholder="账号名"
        @change="dataSearch"
        v-model="params.login_name"
      >
        <el-button slot="append" icon="el-icon-search" @click="dataSearch"></el-button>
      </el-input>
      <el-date-picker
        @change="dataSearch"
        value-format="timestamp"
        v-model="params.before_login_time"
        type="datetime"
        placeholder="登陆时间早于">
      </el-date-picker>
      <el-select
        class="input-s"
        @change="dataSearch"
        clearable
        placeholder="账号状态"
        v-model="params.status">
        <el-option :key="1" label="已禁用" :value="1"></el-option>
        <el-option :key="0" label="已启用" :value="0"></el-option>
      </el-select>
<!--      <el-button class="btn_input" @click="onDialog()">{{ shop_num }} <i v-if="distributorIds.length===0"
          class="el-icon-circle-plus icon"></i><i v-else class="el-icon-circle-close icon" @click.stop="clearDistributor()"></i></el-button>-->
      <el-button type="success" @click="addLabels" icon="el-icon-circle-plus-outline" style="margin-left: 10px">添加账号</el-button>
      <ImportDialog v-if="this.operator_type == 'admin' || this.operator_type == 'staff' || this.operator_type == 'disti'" style="" fileName="导入经销商管理员" fileType="upload_dealer_operator" icon="el-icon-download"></ImportDialog>
      <div style="display: inline-block">
        <el-button type="primary" @click="exportData" style="" icon="el-icon-upload2">导出</el-button>
        <el-popover
          placement="top-start"
          width="200"
          trigger="hover"
          content="导出任务会以队列执行，点击导出后，请至‘设置-导出列表’页面中查看及下载数据">
          <i class="el-icon-question" slot="reference"></i>
        </el-popover>
        <el-link
          type="primary"
          :href=" prefix + '/setting/baseexport?active_type=dealer_operators' "
          target="_blank">
          导出列表
        </el-link>
      </div>
    </el-row>
    <el-table :data="accountsList" :height="wheight-180" v-loading="loading">
      <el-table-column prop="login_name" label="账号"></el-table-column>
<!--      <el-table-column prop="mobile" label="手机号"></el-table-column>-->
<!--      <el-table-column prop="username" label="姓名"></el-table-column>-->
      <el-table-column prop="roles" label="角色">
        <template slot-scope="scope">
          <el-tag v-for="item in scope.row.role_data" :key="item.role_id" size="small" type="primary" effect="dark" style="margin: 5px 5px 0 0;color: #fff" > {{item.role_name}} </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="last_login_time" label="最后登陆时间" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.last_login_time > 0">
            {{scope.row.last_login_time | datetime("yyyy-MM-dd hh:mm:ss")}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="roles" label="状态" width="140">
        <template slot-scope="scope">
          <el-switch
            @change="updateAccountStatus(scope.row)"
            :value="scope.row.status"
            :active-value="0"
            :inactive-value="1"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
          {{scope.row.status == 1 ? '已禁用' : '已启用'}}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template slot-scope="scope">
          <el-button size="mini" @click="editAction(scope.$index, scope.row)" type="primary" plain>编辑</el-button>
          <el-button size="mini" @click="deleteAccountAction(scope.$index, scope.row)" type="danger" plain>删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="content-center content-top-padded">
      <el-pagination
        layout="total, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        :current-page.sync="params.page"
        :total="total_count"
        :page-size="params.pageSize">
      </el-pagination>
    </div>
    <!-- 添加、编辑标识-开始 -->
    <el-dialog :title="editTitle" :visible.sync="editVisible"  :before-close="handleCancel">
      <template>
        <el-form ref="form" :model="form" class="demo-ruleForm" label-width="120px">
          <el-form-item label="登录账号">
            <el-col>
                <el-input v-if="!editLoginName" v-model="form.login_name" :minlength=8 :maxlength=50 placeholder="请输入员工登录账号" show-word-limit></el-input>
                <el-input v-else v-model="form.login_name" :disabled="true"></el-input>
            </el-col>
            <p class="frm-tips">账号由8-50位数字、大小写字母、符号(@-_)组成</p>
          </el-form-item>
<!--          <el-form-item label="手机号">-->
<!--              <el-col :span="10">-->
<!--                  <el-input v-if="!isEdit" v-model="form.mobile" :maxlength=11 placeholder="请输入11位手机号"></el-input>-->
<!--                  <el-input v-else v-model="editMobile" :disabled="true"></el-input>-->
<!--              </el-col>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="姓名">-->
<!--            <el-col :span="10"><el-input required v-model="form.username" placeholder="请填写昵称"></el-input></el-col>-->
<!--          </el-form-item>-->
          <el-form-item label="登录密码">
            <el-col :span="10"><el-input :maxlength=255 v-model="form.password"></el-input></el-col>
          </el-form-item>
          <el-form-item label="角色">
            <el-radio-group v-model="form.role_id">
              <el-radio v-for="role in rolesListData" :label="role.role_id" :key="role.role_id" :value="role.role_id">{{role.role_name}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="所属经销商">
              <el-tag :key="item.dealer_id" class="new-tag" v-for="(item,index) in relDealers" closable :disable-transitions="false" @close="dealerhandleClose(index)"> {{item.name}}
              </el-tag>
              <el-button size="medium" class="button-new-tag" @click="addDealerAction">+ 点击搜索经销商 </el-button>
          </el-form-item>
        </el-form>
      </template>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="handleCancel">取消</el-button>
        <el-button type="primary" @click="submitAction">保存</el-button>
      </div>
    </el-dialog>
    <DealerSelect :store-visible="DealerVisible" :is-valid="isValid" :rel-data-ids="relDealers" :get-status="DealerStatus" @chooseStore="DealerChooseAction" @closeStoreDialog="closeDialogAction" :isSingleSelect="isSingleSelect"></DealerSelect>
    <DistributorSelect :store-visible="DistributorVisible" :rel-data-ids="relDistributors" :get-status="DistributorStatus" @chooseStore="DistributorChooseAction" @closeStoreDialog="handleCloseDialogAction"></DistributorSelect>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { Message } from 'element-ui'
  import {createAccount, getAccountInfo, getAccountList, updateAccountInfo, deleteAccountInfo, getRolesList } from '../../../api/company'
  import {updateAccountStatus } from '../../../api/login'
  import { getDealerList } from '@/api/marketing'
  import ImportDialog from '@/components/importDialog'
  import store from '@/store'
  import DistributorSelect from '@/components/function/distributorSelect'
  import DealerSelect from '@/components/function/dealerSelect'
  export default {
    components: {
      DealerSelect,
      ImportDialog,
      DistributorSelect
    },
    props: {
      status: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        prefix: '',
        accountStatus: '',
        operator_type: '',
        isValid: true,
        DealerStatus: false,
        relDealers: [],
        DealerVisible: false,
        isEdit: false,
        editVisible: false,
        editTitle: '',

        form: {
          operator_type: 'dealer',
          mobile: '',
          login_name: '',
          username: '',
          distributor_ids: [],
          password: '',
          role_id: []
        },
        activeName: 'dealer',
        mobile: '',
        login_name:'',
        editLoginName: '',
        editMobile: '',
        accountsList: [],
        detailData: {},
        loading: false,
        total_count: 0,
        params: {
          login_name: '',
          page: 1,
          pageSize: 20,
          operator_type: 'dealer',
          status: '',
          bind_shop_id: [],
          before_login_time: '',
        },
        operator_id: 0,
        rolesListData: [],
        shop_num: '归属店铺',
        DistributorVisible: false,
        relDistributors: [],
        DistributorStatus: false,
        distributorIds: []
      }
    },
    computed: {
      ...mapGetters([
        'wheight',
        'login_type'
      ]),
      isSingleSelect () {
        const role = this.rolesListData.find(item => item.role_id === this.form.role_id)
        return role && role.role_name !== 'VAD_Admin'
      }
    },
    methods: {
      DistributorChooseAction(data){
        let arr = []
        data.forEach(i => {
          arr.push(i.distributor_id)
        });
        this.DistributorStatus = false
        this.DistributorVisible = false
        this.distributorIds = arr
        this.relDistributors = data
        if (data.length > 0) {
          this.shop_num = `已选择${data.length}门店`
        } else {
          this.shop_num = '归属店铺'
        }
        this.dataSearch()
      },
      onDialog(){
        this.DistributorVisible = true
      },
      clearDistributor(){
        this.distributorIds = []
        this.relDistributors = []
        this.shop_num = '归属店铺'
        this.dataSearch()
      },
      dealerhandleClose(index) {
          this.relDealers.splice(index, 1);
          this.form.distributor_ids.splice(index, 1);
          //this.$forceUpdate()
      },

      addDealerAction() {
        this.DealerVisible = true
        this.DealerStatus = true
      },
      getDealer(ids) {
        let param = {dealer_id: ids}
        getDealerList(param).then( res => {
          this.relDealers = res.data.data.list
        })
      },
      handleCancel () {
        this.editVisible = false
        this.form.operator_type = 'dealer'
        this.operator_id = ''
        this.form.login_name = ''
        this.form.mobile = ''
        this.form.username = ''
        this.form.password = ''
        this.form.distributor_ids = []
        this.form.role_id = []
        this.relDealers = []
      },
      handleCurrentChange (page_num) {
        this.params.page = page_num
        this.getAccountListData()
      },
      addLabels () { // 添加物料弹框
        this.handleCancel()
        this.editTitle = '添加账号信息'
        this.editVisible = true
        this.isEdit = false
        this.form.username = ''
        this.form.login_name = ''
        this.editLoginName = ''
        this.editMobile = ''
        this.operator_id = ''
        this.form.password = ''
        this.form.role_id = []
      },
      editAction (index, row) { // 编辑物料弹框
        this.handleCancel()
        this.editTitle = '编辑账号信息'
        this.editVisible = true
        this.isEdit = true
        this.form.username = row.username
        this.form.login_name = row.login_name
        this.editLoginName = row.login_name
        this.editMobile = row.mobile
        this.operator_id = row.operator_id
        this.form.password = ''
        row.role_data.forEach(item => {
          this.form.role_id = item.role_id
        })

        if (row.distributor_ids && row.distributor_ids.length > 0) {
          let ids = []
          row.distributor_ids.forEach(item => {
              ids.push(item.dealer_id)
          })
          this.getDealer(ids)
        }
      },
      updateAccountStatus (row) {
        let params = {
          operator_id: row.operator_id
        }
        if (row.status == 1) {
          params.status = 0
        } else {
          params.status = 1
        }
        updateAccountStatus(params).then(response => {
          this.$message.success('修改成功')
          this.getAccountListData()
        })
      },
      submitAction () { // 提交物料
        this.form.distributor_ids = []
        // if (this.relDealers.length > 1) {
        //   this.$message({
        //     message: '最多选择一个经销商',
        //     type: 'error',
        //     duration: 3 * 1000
        //   })
        //   return
        // }
        if (this.relDealers.length === 0) {
          this.$message({
            message: '请选择经销商',
            type: 'error',
            duration: 3 * 1000
          })
          return
        }

        if (this.relDealers.length > 0) {
          this.form.operator_type = "dealer"
          this.relDealers.forEach( dealer => {
            this.form.distributor_ids.push({name: dealer.name, dealer_id: dealer.dealer_id});
          })
        }
        if(this.operator_id) {
          updateAccountInfo(this.operator_id, this.form).then(response => {
            this.detailData = response.data.data
            this.editVisible = false
            this.getAccountListData()
          })
        } else {
          createAccount(this.form).then(response => {
            this.detailData = response.data.data
            this.editVisible = false
            this.getAccountListData()
            this.handleCancel()
          })
        }
      },
      dataSearch() {
        this.params.page = 1
        this.params.bind_shop_id = this.distributorIds
        this.getAccountListData()
      },
      getAccountListData (is_export = 0) {
        this.loading = true
        let params = {
          ...this.params,
          is_export: is_export
        }
        getAccountList(params).then(response => {
          if (is_export) {
            this.$message.success('导出成功，请前往导出列表查看')
          } else {
            this.accountsList = response.data.data.list
            this.total_count = response.data.data.total_count
          }
          this.loading = false
        })
      },
      exportData () {
        this.getAccountListData(1)
      },
      deleteAccountAction (index, row) {
        this.$confirm('此操作将删除该账号, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteAccountInfo(row.operator_id).then(response => {
            this.accountsList.splice(index, 1)
            this.$message({
              message: '删除成功',
              type: 'success',
              duration: 5 * 1000
            })
          }).catch(() => {
            this.$message({
              type: 'error',
              message: '删除失败'
            })
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      },
      getRolesListData() {
        var params= {page: 1, pageSize:100, version: 1}
        getRolesList(params).then(res => {
          this.rolesListData = res.data.data.list
        })
      },

      DealerChooseAction (data) {
        this.DealerVisible = false
        this.DealerStatus = false
        if (data === null || data.length <= 0) return
        this.relDealers = data
      },

      closeDialogAction () {
        this.DealerStatus = false
        this.DealerVisible = false
      },
      handleCloseDialogAction(){
        this.DistributorStatus = false
        this.DistributorVisible = false
      }
    },
    mounted () {
      this.operator_type = store.getters.login_type;
      this.getAccountListData()
      this.getRolesListData()

      switch (this.login_type) {
        case 'disti': // 代理端
          this.prefix = '/distiadmin'
          break;
        case 'dealer': // 经销商端
          this.prefix = '/dealeradmin'
          break;
        case 'distributor': // 店铺端
          this.prefix = '/shopadmin';
          break;
      }
    },
    watch: {
      status (val) {
        if (val) {
          this.getAccountListData()
        }
      },
      'form.role_id': function (newVal, oldVal) {
        if(newVal && newVal !== oldVal) {
          this.relDealers = []
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  .el-tag + .el-tag {
    margin-left: 5px;
  }
  .new-tag {
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .button-new-tag {
    height: 40px;
    line-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    height: 40px;
    line-height: 40px;
    width:138px;
    vertical-align: bottom;
  }

  .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 10px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
</style>
