const getters = {
  token: state => state.user.token,
  exp: state => state.user.exp,
  name: state => state.user.name,
  nick_name: state => state.user.nick_name,
  avatar: state => state.user.avatar,
  is_authorizer: state => state.user.is_authorizer,
  license_authorize: state => state.user.license_authorize,
  wxapp_id: state => state.user.wxapp_id,
  template_name: state => state.user.template_name,
  login_type: state => state.user.login_type,
  admin_type: state => state.user.admin_type,
  shopId:state => state.user.shopid,
  golder_color:state => state.user.golder_color,
}
export default getters
