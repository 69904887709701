<template>
  <div>
    <el-alert
      v-if="loginType == 'admin'"
      title="超级管理员账号无法修改密码"
      effect="dark"
      type="warning"
      center
      style="width: 500px; margin-bottom: 15px"
      :closable="false"
      show-icon>
    </el-alert>

    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-form-item label="旧密码" prop="old_pwd">
        <el-input v-model="form.old_pwd" class="input-b" show-password v-if="loginType != 'admin'"></el-input>
        <el-input class="input-b" disabled v-else></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="new_pwd">
        <el-input v-model="form.new_pwd" class="input-b" show-password v-if="loginType != 'admin'"></el-input>
        <el-input class="input-b" disabled v-else></el-input>
        <span style="font-size: small; color: #9a9a9a">&nbsp *密码必须由8-16位数字、大小写字母组成</span>
      </el-form-item>
      <el-form-item label="确认密码" prop="repeat_pwd">
        <el-input v-model="form.repeat_pwd" class="input-b" show-password v-if="loginType != 'admin'"></el-input>
        <el-input class="input-b" disabled v-else></el-input>
        <span style="font-size: small; color: #9a9a9a">&nbsp *确认密码需要与密码保持一致</span>
      </el-form-item>

      <el-form-item>
        <el-button style="text-align: center" type="primary" @click="handleSubmit('form')" :loading="submitButtonLoading"  v-if="loginType != 'admin'">提交</el-button>
        <el-button v-else style="text-align: center" type="primary" disabled>提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
  import { changePassWord } from '@/api/common'

  export default {
    components: {
    },
    data () {
      return {
        loginType: 'admin',
        submitButtonLoading: false,
        form: {
          old_pwd: '',
          new_pwd: '',
          repeat_pwd: '',
        },
        rules: {
          old_pwd: [
            { required: true, message: '请输入旧密码', trigger: 'blur'},
            // { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'change' }
          ],
          new_pwd: [
            { required: true, message: '请输入新密码', trigger: 'blur' },
            { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'change' }
          ],
          repeat_pwd: [
            { required: true, message: '请输入确认密码', trigger: 'blur' },
            { min: 8, max: 16, message: '长度在 8 到 16 个字符', trigger: 'change' }
          ],
        }
      }
    },
    methods: {
      handleSubmit (formName) {
        var formValid = false;
        this.$refs[formName].validate((valid) => {
          formValid = valid
          if (!valid) {
            return false;
          }
        });
        if (!formValid) {
          return false;
        }
        this.submitButtonLoading = true;
        if (this.form.repeat_pwd !== this.form.new_pwd) {
          this.$message.error('密码与确认密码不匹配')
          this.submitButtonLoading = false;
          return false;
        }
        let form = {
          old_pwd: this.form.old_pwd,
          new_pwd: this.form.new_pwd,
        }
        changePassWord (form).then(response => {
          this.$message.success('修改成功，请重新登陆')
          this.logout();
          this.submitButtonLoading = false;
        }).catch(error => {
          this.submitButtonLoading = false;
        })
      },

      logout: function () {
        this.$store.dispatch('unsetToken').then(() => {
          if ((this.system_is_saas == 'true')) {
            getAuthorizelogout().then((res) => {
              window.location.href = res.data.data.url
            })
          } else {
            let location = {path: '/login'}; // 默认进入管理员登陆
            if (this.path_prefixes) {
              location = {path: `/${this.path_prefixes}/login`};
            } else {
              if (this.$route.path.indexOf('dealeradmin') === 1) { // 如果是经销商登陆，退出登陆时进入经销商登陆页面
                location = {path: 'dealeradmin/login'};
              } else if (this.$route.path.indexOf('shopadmin') === 1) { // 如果是店铺管理员登陆，退出登陆时进入经销商登陆页面
                location = {path: 'shopadmin/login'};
              } else if (this.$route.path.indexOf('distiadmin') === 1) {
                location = {path: 'distiadmin/login'};
              }
            }
            // this.$router.push({ path: this.path_prefixes ? `/${this.path_prefixes}/login` : `/login` })
            this.$alert('密码已修改，请重新登陆')
              .then(_ => {
                this.$router.push(location)
              }).catch(err => {
              this.$router.push(location)
            });
          }
        })
      },
    },
    mounted() {
      this.loginType = this.$store.getters.login_type
    }
  }

</script>
<style scoped lang="scss">
.el-row {
  margin-bottom: 10px;
  .frm-tips {
    color: #FF0000;
  }
}
</style>
