<template>
  <div class="container">
    <el-form ref="form" label-width="160px" v-loading="loading">
      <el-card shadow="never" header="接口数据汇总">
        <el-form-item
          label="本月接口请求量"
          prop="request_all_month_num"
        >
          <el-input
            type="number"
            v-model="form.request_all_month_num"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item
          label="本日接口请求量"
          prop="request_all_day_num"
        >
          <el-input
            type="number"
            v-model="form.request_all_day_num"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item
          label="登录过小程序用户"
          prop="statics_login_num"
        >
          <el-input
            type="number"
            v-model="form.statics_login_num"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item
          label="有 union ID 用户"
          prop="statics_user_num"
        >
          <el-input
            type="number"
            v-model="form.statics_user_num"
            :disabled="true"
          />
        </el-form-item>
<!--        <el-form-item-->
<!--          label="有 ExternalID 用户"-->
<!--          prop="statics_external_num"-->
<!--        >-->
<!--          <el-input-->
<!--            type="number"-->
<!--            v-model="form.statics_external_num"-->
<!--            :disabled="true"-->
<!--          />-->
<!--        </el-form-item>-->
        <el-form-item
          label="已同步灵智用户"
          prop="statics_external_num"
        >
          <el-input
            type="number"
            v-model="form.statics_external_num"
            :disabled="true"
          />
        </el-form-item>
      </el-card>
      <el-card shadow="never" header="用户登录时的获取企微客户信息接口配置">
        <template slot="header">
          <span>用户登录时的获取企微客户信息接口配置</span>
          <el-tooltip class="item" effect="dark" content="用户登录时根据unionid从企微获取联系人信息并同步灵智，同一个用户每天只会获取一次并同步" placement="top-start">
            <i class="el-icon-question" />
          </el-tooltip>
        </template>
        <el-form-item
          label="是否开启"
          prop="open_login"
        >
          <el-switch
            v-model="form.open_login"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
        <el-form-item
          label="本月接口请求量"
          prop="login_day"
        >
          <el-input
            type="number"
            v-model="form.request_login_month_num"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item
          label="本日接口请求量"
          prop="login_day"
        >
          <el-input
            type="number"
            v-model="form.request_login_day_num"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item
          label="每月请求最大数量"
          prop="login_month"
        >
          <el-input
            type="number"
            v-model="form.login_month"
            :disabled="!form.open_login"
          />
        </el-form-item>
        <el-form-item
          label="每日请求最大数量"
          prop="login_day"
        >
          <el-input
            type="number"
            v-model="form.login_day"
            :disabled="!form.open_login"
          />
        </el-form-item>
      </el-card>
      <el-card shadow="never" header="定时任务的获取企微客户信息接口配置">
        <template slot="header">
          <span>定时任务的获取企微客户信息接口配置</span>
          <el-tooltip class="item" effect="dark" content="定时任务读取指定天数未同步灵智且有unionid的用户，根据unionid从企微获取联系人信息并同步灵智" placement="top-start">
            <i class="el-icon-question" />
          </el-tooltip>
        </template>
        <el-form-item
          label="是否开启"
          prop="open_schedule"
        >
          <el-switch
            v-model="form.open_schedule"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
        <el-form-item
          label="本月接口请求量"
          prop="login_day"
        >
          <el-input
            type="number"
            v-model="form.request_schedule_month_num"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item
          label="本日接口请求量"
          prop="login_day"
        >
          <el-input
            type="number"
            v-model="form.request_schedule_day_num"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item
          label="每月请求最大数量"
          prop="schedule_month"
        >
          <el-input
            type="number"
            v-model="form.schedule_month"
            :disabled="!form.open_schedule"
          />
        </el-form-item>
        <el-form-item
          label="每日请求最大数量"
          prop="schedule_day"
        >
          <el-input
            type="number"
            v-model="form.schedule_day"
            :disabled="!form.open_schedule"
          />
        </el-form-item>
        <el-form-item
          label="指定天数"
          prop="schedule_over_day_num"
        >
          <template slot="label">
            <span>指定天数</span>

            <el-tooltip class="item" effect="dark" placement="top-start">
              <div slot="content">
                多少天内登录过的用户
              </div>
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <el-input
            type="number"
            v-model="form.schedule_over_day_num"
            :disabled="!form.open_schedule"
          />
        </el-form-item>
        <el-form-item
          label="每分钟读取用户数量"
          prop="schedule_minute"
        >
          <template slot="label">
            <span>每分钟读取用户数量</span>

            <el-tooltip class="item" effect="dark" placement="top-start">
              <div slot="content">
                定时任务按分钟执行
                <br>
                在指定时间范围内，每分钟读取指定数量的指定天数内访问过的用户
                <br>
                按用户数生成异步任务队列，然后从企微获取联系人信息并同步灵智
              </div>
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <el-input
            type="number"
            v-model="form.schedule_minute"
            :disabled="!form.open_schedule"
          />
        </el-form-item>
        <el-form-item
          label="每日执行时间"
          prop="schedule_day_time_frame"
        >
          <template slot="label">
            <span>每日执行时间</span>
            <el-tooltip class="item" effect="dark" content="定时任务每分钟执行一次，选择任务执行的时间" placement="top-start">
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <el-time-picker
            is-range
            v-model="schedule_day_time_frame"
            format="HH:mm"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围"
            :disabled="!form.open_schedule"
          />
        </el-form-item>
      </el-card>
      <el-card shadow="never" header="初始化任务的获取企微客户信息接口配置">
        <template slot="header">
          <span>初始化任务的获取企微客户信息接口配置</span>
          <el-tooltip class="item" effect="dark" content="定时任务读取同步时间为0的用户，根据unionid从企微获取联系人信息并同步灵智" placement="top-start">
            <i class="el-icon-question" />
          </el-tooltip>
        </template>
        <el-form-item
          label="是否开启"
          prop="open_schedule"
        >
          <el-switch
            v-model="form.open_schedule_init_job"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
        <el-form-item
          label="本月接口请求量"
          prop="login_day"
        >
          <el-input
            type="number"
            v-model="form.request_schedule_init_job_month_num"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item
          label="本日接口请求量"
          prop="login_day"
        >
          <el-input
            type="number"
            v-model="form.request_schedule_init_job_day_num"
            :disabled="true"
          />
        </el-form-item>
        <el-form-item
          label="每月请求最大数量"
          prop="schedule_month"
        >
          <el-input
            type="number"
            v-model="form.schedule_init_job_month"
            :disabled="!form.open_schedule_init_job"
          />
        </el-form-item>
        <el-form-item
          label="每日请求最大数量"
          prop="schedule_day"
        >
          <el-input
            type="number"
            v-model="form.schedule_init_job_day"
            :disabled="!form.open_schedule_init_job"
          />
        </el-form-item>
        <el-form-item
          label="每分钟读取用户数量"
          prop="schedule_init_job_minute"
        >
          <template slot="label">
            <span>每分钟读取用户数量</span>

            <el-tooltip class="item" effect="dark" placement="top-start">
              <div slot="content">
                定时任务按分钟执行
                <br>
                每分钟读取指定数量的同步时间为0的用户
                <br>
                按用户数生成异步任务队列，然后从企微获取联系人信息并同步灵智
              </div>
              <i class="el-icon-question" />
            </el-tooltip>
          </template>
          <el-input
            type="number"
            v-model="form.schedule_init_job_minute"
            :disabled="!form.open_schedule_init_job"
          />
        </el-form-item>
      </el-card>
    </el-form>
    <div class="content-center">
      <el-button type="primary" @click="handleSubmit">保存配置</el-button>
    </div>
  </div>
</template>

<script>
import {getSettingByLzSyncWorkWechatExternalUser, setSettingByLzSyncWorkWechatExternalUser} from "../../../api/setting";

export default {
  name: "Wework",
  data() {
    return {
      loading: false,
      schedule_day_time_frame: [(new Date()).setHours(0, 0), (new Date()).setHours(0, 0)],
      form: {
        open_login: false,
        login_day: 0,
        login_month: 0,
        open_schedule_init_job: false,
        schedule_init_job_day: 0,
        schedule_init_job_month: 0,
        schedule_init_job_minute: 0,
        open_schedule: false,
        schedule_day: 0,
        schedule_month: 0,
        schedule_minute: 0,
        schedule_over_day_num: 0,
        schedule_day_start_time_hour: 0,
        schedule_day_start_time_minute: 0,
        schedule_day_end_time_hour: 0,
        schedule_day_end_time_minute: 0,
        request_all_day_num: 0,
        request_all_month_num: 0,
        request_login_day_num: 0,
        request_login_month_num: 0,
        request_schedule_day_num: 0,
        request_schedule_month_num: 0,
        request_schedule_init_job_day_num: 0,
        request_schedule_init_job_month_num: 0,
        statics_user_num: 0,
        statics_login_num: 0,
        // statics_sync_num: 0,
        statics_external_num: 0
      }
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      this.loading = true
      getSettingByLzSyncWorkWechatExternalUser().then(response => {
        this.form = response.data.data
        this.schedule_day_time_frame = [
          (new Date()).setHours(this.form.schedule_day_start_time_hour, this.form.schedule_day_start_time_minute),
          (new Date()).setHours(this.form.schedule_day_end_time_hour, this.form.schedule_day_end_time_minute),
        ]
        this.loading = false
      })
    },
    handleSubmit() {
      const startTime = (new Date(this.schedule_day_time_frame[0]))
      const endTime = (new Date(this.schedule_day_time_frame[1]))
      this.form.schedule_day_start_time_hour = startTime.getHours()
      this.form.schedule_day_start_time_minute = startTime.getMinutes()
      this.form.schedule_day_end_time_hour = endTime.getHours()
      this.form.schedule_day_end_time_minute = endTime.getMinutes()
      this.loading = true
      setSettingByLzSyncWorkWechatExternalUser(this.form).then(response => {
        this.$message({
          message: "添加成功",
          type: "success"
        });
        this.loading = false
        this.getInfo()
      })
    }
  }
}
</script>

<style scoped>

</style>
