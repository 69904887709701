<template>
  <div>
    <el-card shadow="never" header="管理员信息">
      <el-form ref="form" :model="form" label-position="left" label-width="100px">
        <div class="section-body">
          <!--        <el-form-item label="账户">
                    {{id}}
                  </el-form-item>-->
          <el-form-item label="账号">
            <el-input type="text" v-model="form.login_name" style="width:300px" disabled=""></el-input>
          </el-form-item>
          <!--        <el-form-item label="大头照">
                    <imgBox :imgUrl="form.head_portrait" inline @click="handleImgChange"></imgBox>
                    <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog"></imgPicker>
                  </el-form-item>-->

          <template v-if="change_pwd">
            <el-form-item label="修改密码">
              <el-input :type="new_input_type" v-model="form.pwd" style="width:300px" autocomplete="off" placeholder="请输入新密码">
                <i slot="suffix" title="隐藏密码" @click="changeNewPass" style="cursor:pointer;"
                   class="el-icon-view"></i>
              </el-input>
            </el-form-item>
            <el-form-item label="确认修改密码">
              <el-input :type="input_type" v-model="form.repwd" style="width:300px" placeholder="请输入确认密码">
                <i slot="suffix" title="隐藏密码" @click="changePass" style="cursor:pointer;"
                   class="el-icon-view"></i>
              </el-input>
            </el-form-item>
          </template>
          <el-form-item label="订单通知" v-if="form.logintype === 'distributor'">
            <el-switch
              v-model="newOrderNoticeSetting"
              active-value="1"
              inactive-value="0"
              active-text="打开"
              inactive-text="关闭"
              @change="changeNewOrderNoticeSetting">
            </el-switch>
          </el-form-item>
        </div>
        <div class="section-footer content-center">
          <el-button :loading="loading" @click="routeBack">返回</el-button>
          <el-button type="primary" :loading="loading" @click="onSubmit">保存</el-button>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script>
  import { getAdminInfo, updateAdminInfo, getNewOrderNoticeSetting, updateNewOrderNoticeSetting } from '@/api/login'
  import { uploadMaterial } from '@/api/wechat'
  import imgPicker from '@/components/imageselect'
  import imgBox from '@/components/element/imgBox'
  import { websocket } from '@/mixins/websocket'
  export default {
    mixins: [websocket],
    components: {
      imgPicker,
      imgBox
    },
    data () {
      return {
        newOrderNoticeSetting: '0',
        change_pwd:false,
        loading: false,
        id: '',
        form: {
          username: '',
          head_portrait: '',
          pwd:'',
          repwd:'',
          logintype:''
        },
        pic: '',
        fileList: [],
        imgDialog: false,
        isGetImage: false,
        input_type:"password",
        new_input_type:"password",
      }
    },
    methods: {
      onSubmit () {
        const that = this
        this.loading = true
        updateAdminInfo(this.form).then(response => {
          if(response.data.data) {
            this.$message({
              message: '更新成功',
              type: 'success',
              onClose(){
                that.loading = false
                that.$router.go(-1)
              }
            })
          }
        })
      },
      changeNewPass(){
        var new_type = (this.new_input_type == 'text')?'password':'text';
        this.new_input_type = new_type;
      },
      changePass(){
       var type = (this.input_type == 'text')?'password':'text';
       this.input_type = type;
      },
      // handleQualificationPicChange: function(file, fileList) {
      //   this.pic = file.url
      //   if (file && file.raw) {
      //     if (file.raw.type != 'image/jpeg' && file.raw.type != 'image/png') {
      //       this.$message.error('上传图片只能是 JPG 或者 PNG 格式!');
      //     }
      //     if (file.raw.size/1024/1024 > 2) {
      //       this.$message.error('上传图片大小不能超过 2MB!')
      //     }
      //   }

      //   let params = {isUploadFile: true, file: file.raw, type: 'image'}
      //   uploadMaterial(params).then(res => {
      //     this.form.logo = res.data.data.url
      //   })
      // },
      handleImgChange () {
        this.imgDialog = true
        this.isGetImage = true
      },
      pickImg (data) {
        this.form.head_portrait = data.url
        this.imgDialog = false
      },
      closeImgDialog () {
        this.imgDialog = false
      },
      routeBack () {
        this.$router.back(-1)
      },
      changeNewOrderNoticeSetting(value) {
        this.newOrderNoticeSetting = value
        let params = {
          'is_open': this.newOrderNoticeSetting
        }
        updateNewOrderNoticeSetting(params).then(response => {
          if (response.data.data.success == true) {
            this.$message.success('更新成功')
            if (this.newOrderNoticeSetting == 0) {
              this.gws.ws.close()
            } else {
              // this.initWebSocket();
            }
          } else {
            this.$message.error('更新失败')
          }
        })
      },
      getNewOrderNoticeSetting() {
        getNewOrderNoticeSetting().then(response => {
          this.newOrderNoticeSetting = response.data.data.is_open
        })
      },
    },
    mounted () {
      getAdminInfo().then(res => {
        if(res.data.data.logintype == 'admin'){
            this.change_pwd = false
        }else{
          this.change_pwd = true
        }
        this.form.head_portrait = res.data.data.head_portrait
        this.form.username = res.data.data.username
        this.form.logintype = res.data.data.logintype
        this.form.login_name = res.data.data.login_name
        this.id = res.data.data.mobile
      })
      this.getNewOrderNoticeSetting()
    },
  }
</script>

<style lang="scss">
</style>
