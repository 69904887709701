<template>
  <div class="section-body">
    <el-form label-width="200px">
      <el-card class="box-card">
        <el-form-item label="是否开启">
            <el-switch
              v-model="form.is_open"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
        </el-form-item>
      </el-card>
      <el-card class="box-card">
        <el-alert class='mb_15' type="info" title="访问密钥" show-icon description="请登录 https://console.cloud.tencent.com/cam/capi -> 访问密钥 -> API密钥管理 查看">
        </el-alert>
        <el-form-item label="SecretId">
          <div>
            <el-input class="input-b" v-model="form.secret_id"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="SecretKey">
          <div>
            <el-input class="input-b" v-model="form.secret_key"></el-input>
          </div>
        </el-form-item>
      </el-card>
      <el-card class="box-card">
        <el-alert class='mb_15' type="info" title="验证码配置" show-icon description="请登录[验证码控制台] https://console.cloud.tencent.com/captcha/graphical 查看">
        </el-alert>
        <el-form-item label="CaptchaAppId">
          <div>
            <el-input class="input-b" v-model="form.captcha_app_id"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="AppSecretKey">
          <div>
            <el-input class="input-b" v-model="form.app_secret_key"></el-input>
          </div>
        </el-form-item>
      </el-card>
    </el-form>
    <div style="text-align: center; margin-top: 20px">
      <el-button type="primary" @click="saveConfig">保存配置</el-button>
    </div>
  </div>
</template>
<script>
import {
  saveConfig,
  getConfig
} from '../../../api/stepcheck'
  export default {
    data () {
      return {
        form: {
          is_open: false,
          secret_id: '',
          secret_key: '',
          captcha_app_id: '',
          app_secret_key: ''
        }
      }
    },
    methods: {
      saveConfig () {
        let params = this.form
        saveConfig(params).then(res => {
          if (res.data.data.status == true) {
            this.$message.success('保存成功')
          } else {
            this.$message.error('保存失败')
          }
        }).catch(error => {
          this.$message.error('保存失败');
        })
      },
      getConfig () {
        getConfig().then(res => {
          this.form = res.data.data;
        })
      }
    },
    mounted () {
      this.getConfig();
    }
  }
</script>
<style scoped type="text/css" lang="scss">
  .mb_15 {
    margin-bottom: 15px;
  }
  .span-tip{
    color: #8c939d;
    font-size: small;
  }
</style>
