const errorCode = {
  "code-1": '系统错误，请联系管理员',
  code48005: '删除失败，改素材自动回复或自定义菜单引用',
  //激活相关
  code400002: '系统未激活或者被关闭',
  // 客服相关
  code65400: '公众号未开通或升级到新客服功能，请先开通并授权',
  code65403: '客服昵称，最长16个字',
  code65409: '无效的微信号',
  code61007: '无权限访问，请重新授权',
  code48001: '无权限访问，请重新授权',
  code65404: '当前微信号未关注公众号，请先关注',
  code85038: '门店已经推送过，无需再次推送',
  // 添加门店
  // code85038: '请勿添加重复门店',
  code85039: '此门店状态不能被获取信息',
  code85040: '此门店已被绑定，无需重复绑定',
  code85041: '该经营资质已添加，请勿重复添加',
  code85042: '附近地点添加数量达到上线，无法继续添加',
  code85054: '由于门店小程序还没升级成功，需要去微信管理平台升级原门店为门店小程序',
  code85055: '门店地图选点无效，请确认门店在腾讯地图中已经添加过', // map_poi_id无效
  code85056: '资质证明材料无效', // 临时mediaid无效
  // 更新门店
  code65115: '门店不存在',
  code65118: '门店未审核之前不能更新', // 该门店状态不允许更新
  code85053: '只有创建过门店才能操作', // 请先成功创建门店后再调用

  //客服
  // code65403: '客服昵称不合法',
  code65405: '帐号数目已达到上限，不能继续添加',
  code65406: '已经存在的客服帐号',
  code65401: '无效客服帐号',
  code65407: '邀请对象已经是本公众号客服',
  code65408: '本公众号已发送邀请给该微信号',
  // code65409: '无效的微信号',
  code65410: '邀请对象绑定公众号客服数量达到上限',
  code65411: '该帐号已经有一个等待确认的邀请，不能重复邀请',
  code65412: '该帐号已经绑定微信号，不能进行邀请',

  code89001: '小程序与公众号开放平台帐号主体不相同',
  code89003: '公众号或小程序已绑定的开放平台账号，为手动创建，无权限操作。请手动绑定或解绑后在操作',
  code89004: '开放平台帐号所绑定的公众号/小程序已达上限',


  code85009: '小程序已经提交审核，请审核后在更新'
}

export default errorCode

