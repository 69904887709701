<template>
  <div>
    <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
      <el-tab-pane label="微信支付配置" name="wxpay" v-if="!isHfpay">
        <wxpayTemplates></wxpayTemplates>
      </el-tab-pane>
      <el-tab-pane label="支付宝支付配置" name="alipay" v-if="!isHfpay">
        <alipayTemplates></alipayTemplates>
      </el-tab-pane>
      <el-tab-pane label="ebuy支付配置" name="ebuypay" v-if="!isHfpay">
        <ebuypayTemplates></ebuypayTemplates>
      </el-tab-pane>
      <el-tab-pane label="ADA PAY配置" name="adapay" v-if="!isHfpay && false">
        <adapayTemplates></adapayTemplates>
      </el-tab-pane>
      <el-tab-pane label="汇付天下支付配置" name="hfpay" v-if="isHfpay">
        <hfpayTemplates></hfpayTemplates>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import alipayTemplates from './payment/alipay'
  import wxpayTemplates from './payment/wxpay'
  import ebuypayTemplates from './payment/ebuypay'
  import hfpayTemplates from './payment/hfpay'
  import adapayTemplates from './payment/adapayment'
import { hfpayVersionStatus } from '@/api/fenzhang'

  export default {
    components: {
      alipayTemplates,
      wxpayTemplates,
      ebuypayTemplates,
      hfpayTemplates,
      adapayTemplates,
    },
    data () {
      return {
        showDialog: false,
        activeName: 'wxpay',
        isHfpay:false,
        payType: {
          chinapnr: false,
          ebuy: false,
          balance: false,
          point: false
        }
      }
    },
    methods: {
      handleClick (tab, event) {
        if (this.activeName === "wxpay") {
        } else if (this.activeName === "alipay") {
        }
      },
      handleChange () {
        this.showDialog = true
      },
      cancelAction () {
        this.showDialog = false
      },
      handleSubmit () {
        this.showDialog = false
      }
    },
    mounted() {
      if('undefined' != typeof(this.$route.query.activeName)) {
        this.activeName = this.$route.query.activeName
      }
      hfpayVersionStatus().then(res=>{
        let data=res.data.data
        if(data.hfpay_version_status){
          this.activeName='hfpay'
          this.isHfpay=true
        }else{
          this.activeName='wxpay'
          this.isHfpay=false
        }
      })
    }
  }

</script>
<style scoped lang="scss">
.el-row {
  margin-bottom: 10px;
  .frm-tips {
    color: #FF0000;
  }
}
</style>
