<template>
  <el-form ref="form" :model="form" label-width="200px" :rules="rules">
    <el-form-item label="ADAPAY_APP_ID" prop='app_id'>
      <el-input v-model="form.app_id" style="width:450px"/>
      <span class="frm-tips">汇付商户后台调用支付的应用ID</span>
    </el-form-item>
    <el-form-item label="ADAPAY_KEY_LIVE" prop='key_live'>
      <el-input v-model="form.key_live" style="width:450px"/>
      <span class="frm-tips">prod 环境下交易密钥</span>
    </el-form-item>
    <el-form-item label="ADAPAY_RSA_PRIVATE_KEY" prop='rsa_private_key'>
      <el-input
        type="textarea"
        :rows="7"
        placeholder="请输入内容"
        style="width:450px"
        maxlength="1500"
        show-word-limit
        v-model="form.rsa_private_key"
      />
      <br/>
      <p class="frm-tips" style="margin-left:0px">用于商户发起交易接口请求时, AdaPay 对请求对象进行 RSA 加密验签使用</p>
    </el-form-item>
    <el-form-item label="ADAPAY_RSA_PUBLIC_KEY" prop='rsa_public_key'>
      <el-input
        type="textarea"
        :rows="7"
        maxlength="500"
        placeholder="请输入内容"
        style="width:450px"
        show-word-limit
        v-model="form.rsa_public_key"
      />
      <br/>
      <span class="frm-tips">用于 AdaPay 异步回调时，商户 Server 验证请求对象的正确性</span>
    </el-form-item>
    <div class="section-footer">
      <el-form-item>
        <el-button type="primary" v-loading="loading" @click="onSubmitFrom('form')">保存</el-button>
      </el-form-item>
    </div>
  </el-form>
</template>
<script>
import {getAdapayDetail, saveAdapayFrom} from '@/api/trade'

export default {
  data() {
    return {
      activeName: '汇付天下支付配置',
      loading: false,
      form: {
        app_id: '',
        key_live: '',
        rsa_private_key: '',
        rsa_public_key: '',
      },
      rules: {
        app_id: [
          {required: true, message: '请输入应用ID', trigger: 'blur'}
        ],
        key_live: [
          {required: false, message: '请输入API_KEY', trigger: 'blur'}
        ],
        rsa_private_key: [
          {required: true, message: '请输入商户RSA私钥', trigger: 'blur'}
        ],
        rsa_public_key: [
          {required: false, message: '请输入AdapayRSA公钥', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    onGetDetail() {
      getAdapayDetail().then(res => {
        this.form = res.data.data
      })
    },
    onSubmitFrom(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.loading = true
          saveAdapayFrom(this.form).then(response => {
            this.loading = false
            this.$message.success('保存成功')
            this.onGetDetail()
          }).catch(error => {
            this.loading = false
          })
        }
      })
    }
  },
  mounted() {
    this.onGetDetail()
  }
}
</script>
<style scoped lang="scss">
.frm-tips {
  margin-left: 10px;
}
</style>
