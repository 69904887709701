import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Routes from "./routers";

import auth from "./auth"; // 绑定
import Login from "@/view/login"; // 登录
import Forget from "@/view/forget"; // 登录
import shopLogin from "@/view/shoplogin"; // 店铺登录
import dealerLogin from "@/view/dealerlogin"; // 店铺登录
import distiLogin from "@/view/distilogin"; // 店铺登录
import shopList from "@/view/shoplist"; // 店铺登录
import Record from "@/view/vshopuserrecord"; // 未绑定微信的空页面
import Unbind from "@/view/base/empty/unbind"; // 未绑定微信的空页面
import { registerMicroApps, loadMicroApp, start } from "qiankun";
import fetch from "../utils/fetch";
import loadRouters from "./loadRouters";
import activeLicense from "@/view/base/asset/accountactivation/index";
import Layout from '@/view/layout' // 主结构
import IframeLogin from '@/view/base/iframeLogin' // 自动登录页面
import { actions } from "@/utils/micr-app";
import { reslovePermissionData, IS_ADMIN, IS_DISTRIBUTOR, IS_DISTI_ADMIN, IS_DEALER } from '@/utils'
Vue.use(VueRouter);

const pathPrefix = process.env.VUE_APP_PREFIXES;

console.log('VUE_APP_PREFIXES', process.env.VUE_APP_PREFIXES);
console.log('loginType', store.getters.login_type)

let passport = {
  path: pathPrefix ? `/${pathPrefix}/login` : "/login",
  component: Login,
  name: "",
  hidden: true,
};

let forget = {
  path: pathPrefix ? `/${pathPrefix}/forget` : "/forget",
  component: Forget,
  name: "",
  hidden: true,
};

let shoppassport = {
  path: pathPrefix ? `/${pathPrefix}/shopadmin/login` : "/shopadmin/login",
  component: shopLogin,
  name: "",
  hidden: true,
};

let dealerpassport = {
  path: pathPrefix ? `/${pathPrefix}/dealeradmin/login` : "/dealeradmin/login",
  component: dealerLogin,
  name: "",
  hidden: true,
};

let distipassport = {
  path: pathPrefix ? `/${pathPrefix}/distiadmin/login` : "/distiadmin/login",
  component: distiLogin,
  name: "",
  hidden: true,
};

let shoplistpassport = {
  path: pathPrefix
    ? `/${pathPrefix}/shopadmin/shoplist`
    : "/shopadmin/shoplist",
  component: shopList,
  name: "店铺选择列表页",
  hidden: true,
};

let unbind = {
  path: pathPrefix ? `/${pathPrefix}/unbind` : "/unbind",
  component: Unbind,
  name: "绑定页",
  hidden: true,
};

let activelicense = {
  path: pathPrefix ? `/${pathPrefix}/activelicense` : "/activelicense",
  component: activeLicense,
  name: "未登录用户激活页",
  hidden: true,
};

let record = {
  path: "/record",
  component: Record,
  name: "用户体测报告",
  hidden: true,
};

let iframeLogin = {
  path: '/iframeLogin',
  component: IframeLogin,
  name: '',
  hidden: true
}

let prefixType = ''
if (IS_DISTRIBUTOR()) {
  prefixType = '/shopadmin'
} else if (IS_DEALER()) {
  prefixType = '/dealeradmin'
} else if (IS_DISTI_ADMIN()) {
  prefixType = '/distiadmin'
}

const decorateRoute = {
  path: `${prefixType}/wxapp/manage/decorate`,
  name: `模板装修`,
  component: () => import('@/view/decorate/index'),
  hidden: true
}

const router = new VueRouter({
  routes: [
    passport,
    shoppassport,
    dealerpassport,
    distipassport,
    shoplistpassport,
    unbind,
    forget,
    auth,
    activelicense,
    record,
    iframeLogin,
    decorateRoute,
    // ...(loadRouters.addRouters() || ""),
  ],
  mode: "history",
});

// const routerBefore = {
//   goToPath: function (to, from, next) {
//     let licenseValid = JSON.parse(
//       Base64.decode(store.getters.license_authorize)
//     );
//     if (
//       licenseValid &&
//       (licenseValid.valid == "false" || !licenseValid.valid)
//     ) {
//       if (to.path.indexOf("assetaccountactivation") === -1) {
//         next({ path: matchInternalRoute("assetaccountactivation") });
//         NProgress.done();
//       } else {
//         next();
//       }
//     } else if (to.path === passport.path) {
//       next({ path: pathPrefix ? `/${pathPrefix}` : "/" });
//       NProgress.done();
//     } else if (to.path === shoppassport.path) {
//       next({ path: pathPrefix ? `/${pathPrefix}/shopadmin` : "/shopadmin" });
//       NProgress.done();
//     } else if (to.path === dealerpassport.path) {
//       next({ path: pathPrefix ? `/${pathPrefix}/dealeradmin` : "/dealeradmin" });
//       NProgress.done();
//     } else if (to.path === distipassport.path) {
//       next({ path: pathPrefix ? `/${pathPrefix}/distiadmin` : "/distiadmin" });
//       NProgress.done();
//     } else {
//       next();
//     }
//   },
// };

// const matchInternalRoute = function (name) {
//   const menus = store.getters.menus;
//   if (menus) {
//     return findName(menus, name);
//   }
//   function findName(menus, name) {
//     for (let item of menus) {
//       let url = item.url.split("/");
//       if (url[url.length - 1] === name) {
//         return item.url;
//       }
//       if (item.children && item.children.length > 0) {
//         let obj = findName(item.children, name);
//         if (obj) {
//           return obj;
//         }
//       }
//     }
//   }
// };

// 不重定向白名单
const whiteList = [
  passport.path,
  forget.path,
  shoppassport.path,
  dealerpassport.path,
  distipassport.path,
  activelicense.path,
  '/record',
  '/iframeLogin'
]

router.beforeEach((to, from, next) => {
  if (to.path.includes("/pc/design")) {
    const { id } = to.query;
    console.log('【shop】id:', id)
    actions.setGlobalState({
      mode: "pc",
      token: store.getters.token,
      pageid: id
    });
  }

  // if (to.path.indexOf("shopadmin") !== -1) {
  //   store.dispatch("setLoginType", "distributor");
  // } else if (to.path.indexOf("dealeradmin") !== -1) {
  //   store.dispatch("setLoginType", "dealer");
  // } else if (to.path.indexOf("distiadmin") !== -1) {
  //   store.dispatch("setLoginType", "disti");
  // } else {
  //   store.dispatch("setLoginType", store.getters.login_type);
  // }

  NProgress.start();

  const timestamp = Date.parse(new Date()) / 1000;
  if (store.getters.token && whiteList.indexOf(to.path) === -1) {
    // 验证token有效期 提前30分种刷新token
    if (Number(store.getters.exp) - timestamp <= 1800) { // 1800
      fetch({ url: "/token/refresh", method: "get" })
        .then((response) => {
          console.log(response, '=== token/refresh ===')
          store.dispatch("setToken", response.headers.authorization.replace('Bearer ', ''));
          // routerBefore.goToPath(to, from, next);
          next({ ...to })
        })
        .catch((error) => {
          if (to.path.indexOf("shopadmin") !== -1) {
            next(shoppassport.path); // 否则全部重定向到登录页
          } else if (to.path.indexOf("dealeradmin") !== -1) {
            next(dealerpassport.path); // 否则全部重定向到登录页
          } else if (to.path.indexOf("distiadmin") !== -1) {
            next(distipassport.path); // 否则全部重定向到登录页
          } else {
            next(passport.path); // 否则全部重定向到登录页
          }
          NProgress.done(); // 在hash模式下 改变手动改变hash 重定向回来 不会触发afterEach 暂时hack方案 ps：history模式下无问题，可删除该行！
        });
    } else if (store.getters.menus.length == 0) {
      fetch({ url: "/permission", method: "get" }).then((response) => {
        // TODO:根据登录平台，处理菜单数据，
        const data = reslovePermissionData(response.data.data)
        store.dispatch("setMenu", data);
        const routes = loadRouters.addRouters(data)
        router.addRoutes(routes)
        // TODO:访问的path是否存在
        const path = loadRouters.isValidateRouter(to.path,to.fullPath)
        next({ path, replace: true })
      });
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      if (to.path.indexOf("shopadmin") !== -1) {
        next(shoppassport.path); // 否则全部重定向到登录页
      } else if (to.path.indexOf("dealeradmin") !== -1) {
        next(dealerpassport.path); // 否则全部重定向到登录页
      } else if (to.path.indexOf("distiadmin") !== -1) {
        next(distipassport.path); // 否则全部重定向到登录页
      } else {
        next(passport.path); // 否则全部重定向到登录页
      }
    }
  }
});

router.afterEach((transition) => {
  NProgress.done();
});

export default router;
