function deepCopy (obj) {
  let result = obj instanceof Array ? [] : {}
  for (let key in obj) {
    if (obj[key] instanceof Object || obj[key] instanceof Array) {
      result[key] = deepCopy(obj[key])
    } else {
      result[key] = obj[key]
    }
  }
  return result
}

export { deepCopy }
