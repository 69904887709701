<template>
  <div>
    <el-tabs @change="getMsgConfig" v-model="activeName" type="border-card">
      <el-tab-pane label="店铺小程序码" name="storeQrcode">
        <el-form label-width="200px">
          <el-card class="box-card">
            <el-alert
              class="mb_15"
              type="info"
              title="店铺小程序码-线下"
              show-icon
              description="线下,展示使用，跳转到店铺首页"
            >
            </el-alert>
            <el-form-item label="生成文件名">
              <div>
                <el-input
                  class="input-b"
                  v-model="store_qrcode_config.fileName_off"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="小程序码参数">
              <div>
                <el-input
                  class="input-b"
                  v-model="store_qrcode_config.fileParams_off"
                ></el-input>
              </div>
            </el-form-item>
          </el-card>

          <el-card class="box-card">
            <el-alert
              class="mb_15"
              type="info"
              title="店铺小程序码-线上"
              show-icon
              description="线上,推广使用，跳转到店铺首页"
            >
            </el-alert>

            <el-form-item label="生成文件名">
              <div>
                <el-input
                  class="input-b"
                  v-model="store_qrcode_config.fileName_on"
                ></el-input>
              </div>
            </el-form-item>
            <el-form-item label="小程序码参数">
              <div>
                <el-input
                  class="input-b"
                  v-model="store_qrcode_config.fileParams_on"
                ></el-input>
              </div>
            </el-form-item>
          </el-card>
        </el-form>
        <div style="text-align: center; margin-top: 20px">
          <el-button type="primary" @click="saveQrcodeConfig">保存配置</el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getStoreQrcodeSetting, setStoreQrcodeSetting } from "../../../api/wxa";

export default {
  data() {
    return {
      store_qrcode_config: {
        fileName_off: "",
        fileParams_off: "",
        fileName_on: "",
        fileParams_on: "",
      },

      detailDialog: false,
      activeName: "storeQrcode",
      messageCount: 0,
      smsTemlateList: [],
      messageAutograph: "",
      sms_buy_url: "",
      // currentTemplate: {
      //   is_open: false,
      //   content: '',
      //   send_time_desc: {
      //     title: ''
      //   }
      // }
    };
  },
  methods: {
    saveQrcodeConfig() {
      console.log("store_qrcode_config:", this.store_qrcode_config);

      let params = {
        store_qrcode_config: JSON.parse(JSON.stringify(this.store_qrcode_config)),
        type: this.activeName,
      };
      console.log("store_qrcode_config:", this.params);

      setStoreQrcodeSetting(params)
        .then((res) => {
          console.log("set res.data :::", res.data.data);

          if (res.data.data.status == "success") {
            this.$message.success("保存成功");
          } else {
            this.$message.error("保存失败");
          }
        })
        .catch((error) => {
          this.$message.error("保存失败");
        });
    },

    getMsgConfig() {
      let params = { type: "" };
      getStoreQrcodeSetting(params).then((res) => {
        if( res.data.data.store_qrcode_config !== ""){
          
          console.log("get res.data :::", res.data.data);
          this.store_qrcode_config = res.data.data.store_qrcode_config;
        }
        //   if (this.activeName == 'tencent') {
        // }
      });
    },

    closeDialog() {
      this.detailDialog = false;
    },
    // toDetail (params) {
    //   this.detailDialog = true
    //   this.currentTemplate = params
    // },
    // openChange (is_open) {
    //   let query = {template_name: this.currentTemplate.tmpl_name, is_open: is_open}
    //   updateSmsTemplate(query).then(res => {
    //     console.log(res)
    //   })
    // },
    saveSmsSignAction() {
      let query = { sign: this.messageAutograph };
      saveSmsSign(query).then((res) => {
        this.$message({
          type: "success",
          message: "设置短信签名成功",
        });
      });
    },
  },
  mounted() {
    console.log("test xcx qrcode -------001 start");
    this.getMsgConfig();
    // getSmsBasic().then(response => {
    //   if(response.data.data.sms_remainder) {
    //     let sms_remainder = response.data.data.sms_remainder.info
    //     this.messageCount = sms_remainder.all_residual
    //   }
    //   this.sms_buy_url = response.data.data.sms_buy_url
    // })
    // getSmsTemplateList().then(res => {
    //   this.smsTemlateList = res.data.data.list
    // })
    // getSmsSign().then(res => {
    //   this.messageAutograph = res.data.data.sign
    // })
  },
};
</script>
<style scoped type="text/css" lang="scss">
.el-tab-pane {
  min-height: 700px;
}
.message-content {
  button {
    margin-left: 20px;
  }
}
.message-count {
  margin: 0 5px 0 20px;
  font-size: 24px;
  color: #ff5000;
}
.message-template {
  .item {
    display: inline-block;
    width: 210px;
    margin: 0 10px;
    border: 1px solid #dfdfdf;
    &-title {
      padding: 10px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      i {
        float: right;
        margin-top: 4px;
      }
    }
    &-content {
      height: 140px;
      padding: 20px 10px;
      color: #333;
      font-size: 12px;
    }
    &-footer {
      // border-top: 1px solid #dfdfdf;
      padding: 10px 0;
      text-align: center;
      button {
        width: 50%;
        &:hover {
          border: 1px solid #c4c4c4;
          color: #1f2d3d;
        }
      }
    }
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    &.not-open-sms {
      .item-title {
        background-color: #ff4949;
      }
    }
    &.succ-open-sms {
      .item-title {
        background-color: #13ce66;
      }
    }
  }
}
.message-autograph {
  .el-input {
    width: 240px;
    margin-right: 10px;
  }
  span {
    margin: 0 10px;
  }
  button {
    width: 80px;
  }
}
.message-prompt {
  margin-top: 30px;
  padding-left: 20px;
  .prompt {
    &-title {
      margin-bottom: 10px;
      font-size: 18px;
      color: #333;
      span {
        border-left: 3px solid #20a0ff;
        padding-left: 10px;
      }
    }
    &-content {
      padding-left: 8px;
      line-height: 1.6;
      color: #666;
      .item-title {
        margin-bottom: 5px;
      }
      .item-content {
        padding-left: 14px;
        margin-bottom: 10px;
      }
    }
  }
}
.mb_15 {
  margin-bottom: 15px;
}
.span-tip {
  color: #8c939d;
  font-size: small;
}
</style>
