<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1 && $route.path.indexOf('_detail') === -1" >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input  class="input-b" placeholder="请输入分组名称" v-model="params.search"     size="medium">
            <template slot="append">
              <el-button  @click="getGroupLists">搜索</el-button>
            </template>
          </el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="16">
          <el-button
            v-if="login_type === 'disti'"
            type="primary"
            size="medium"
            icon="el-icon-circle-plus"
            @click="handleNew(false)">
            新增分组
          </el-button>
        </el-col>

      </el-row>
      <el-tabs type="border-card">
        <el-table :data="list" style="width: 100%" :height="wheight-190" v-loading="loading" element-loading-text="数据加载中">
          <el-table-column prop="group_id" width="200" label="ID"></el-table-column>
          <el-table-column prop="group_name" width="200" label="分组名称"></el-table-column>
          <el-table-column prop="created" width="200" label="创建时间">
            <template slot-scope="scope" >
              <span v-if="scope.row.created">{{ scope.row.created | datetime("yyyy-MM-dd hh:mm:ss") }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="show"  label="店铺（对应经销商）">
            <template slot-scope="scope" >
              <el-button type="text"  size="mini" @click="showGroupShops(scope.row.group_id,scope.row.group_name)">查看店铺</el-button> ｜
              <el-button type="text"  size="mini" @click="showGroupShops(scope.row.group_id,scope.row.group_name,true)">查看经销商</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="show"  label="角色">
            <template slot-scope="scope" >
              <el-button type="text"  size="mini" @click="showGroupRoles(scope.row.group_id,scope.row.group_name)">查看绑定角色</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="bind_time"  label="操作">
            <template slot-scope="scope" >
              <el-button type="text"  size="mini" icon="el-icon-delete" @click="deleteGroup(scope.row.group_id)">删除</el-button>
              <el-button type="text"  size="mini" icon="el-icon-delete" @click="updateGroup(scope.row.group_id)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="content-padded content-center">
          <el-pagination
            layout="total, prev, pager, next"
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :total='total_count' :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>
    </div>
    <el-dialog  :title="shopsTitle" :visible.sync="showShop"  :before-close="handleShopCancel">
      <template>
        <el-tag type="success"   effect="dark">共{{relStoresListLength}}家门店</el-tag><br /><br />
        <el-input :span="10" v-model="search"  placeholder="请输入店铺名或者店铺code" style="width: 300px"/>
        <el-table  v-loading='shopLoading' ref="singleTable"   :data="relStoresList.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.shop_code.includes(search))"
                  style="width: 100%">

          <el-table-column prop="distributor_id" label="Id"></el-table-column>
          <el-table-column prop="shop_code" label="店铺Code"></el-table-column>
          <el-table-column prop="name" label="名称" ></el-table-column>
        </el-table>
      </template>
    </el-dialog>
    <el-dialog  :title="shopsTitle" :visible.sync="showShopDealer"  :before-close="handleDealerCancel">
      <el-tag type="success"   effect="dark">共{{relDealerListLength}}家经销商</el-tag><br /><br />
      <template>
        <el-input :span="10" v-model="search_dealer"  placeholder="请输入经销商名或者经销商HQID" style="width: 300px"/>
        <el-table v-loading='dealerLoading'    ref="singleTable"   :data="relDealerList.filter(data => !search_dealer || data.name.toLowerCase().includes(search_dealer.toLowerCase()) || data.dealer_hqid.includes(search_dealer))"
                  style="width: 100%">
          <el-table-column prop="dealer_id" label="ID"></el-table-column>
          <el-table-column prop="dealer_hqid" label="HQID"></el-table-column>
          <el-table-column prop="name" label="经销商名称" ></el-table-column>
          <el-table-column prop="channel_rtm" label="rtm" ></el-table-column>
        </el-table>
      </template>
    </el-dialog>
    <el-dialog :title="rolesTitle" :visible.sync="showRoles"  :before-close="handleRolesCancel">
      <template>
        <el-input :span="10" v-model="search_role"  placeholder="请输入角色名称" style="width: 300px"/>
        <el-table ref="singleTable"  style="width: 100%" :data="bindRoles.filter(data => !search_role || data.role_name.toLowerCase().includes(search_role.toLowerCase()))">
          <el-table-column prop="role_id" label="角色id"></el-table-column>
          <el-table-column prop="role_name" label="角色名称"></el-table-column>
        </el-table>
      </template>
    </el-dialog>

      <sideBar :visible.sync="show_sideBar"  width=55 :title="'分组信息'">
        <el-form v-loading='listLoading'>
          <el-form-item label="分组名称">
            <el-input v-model="form.group_name"/>
          </el-form-item>
          <el-form-item label="">
            <el-col :span="24">
              <el-button type="primary" @click="relStoresClick(0)">选店铺</el-button>
              <template>
                <div style="margin-top: 10px">
                  <el-input :span="10" v-model="save_search"  placeholder="请输入店铺名或者店铺code" style="width: 300px"/>
                   <el-tag type="success"   effect="dark">当前已选择{{relStores.length}}家门店</el-tag>
                  <br>
                  <el-tag type="warning" >此筛选仅用于验证是否选择某些门店，不影响最终提交的门店数据</el-tag>
                </div>
                <el-table stripe border highlight-current-row style="width: 100%;margin-top: 10px"  :data="relStores.filter(data => !save_search || data.name.toLowerCase().includes(save_search.toLowerCase()) || data.shop_code.includes(save_search))">
                  <el-table-column prop="distributor_id" label="Id" width="60"></el-table-column>
                  <el-table-column prop="shop_code" label="店铺Code" width="150"></el-table-column>
                  <el-table-column prop="name" label="名称" width="200"></el-table-column>
                  <el-table-column prop="option" label="操作">
                    <template slot-scope="scope">
                      <el-button type="text" size="mini" icon="el-icon-delete"
                                 @click="deleteRow(scope.$index, form.items)">删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-col>
          </el-form-item>
        </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </sideBar>
    <StoreSelect
      :store-visible="storeVisible"
      :is-valid="true"
      :rel-data-ids="relStores"
      :get-status="setStatus"
      @chooseStore="chooseStoreAction"
      @closeStoreDialog="closeStoreDialogAction"
      :typeStores="typeStores"
      :set-page-size="100"
    ></StoreSelect>
    <router-view></router-view>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ImportDialog from '@/components/importDialog'
import {getGroupLists, setGroup,getGroupDetail,deleteGroup,getGroupBindRoles} from '@/api/company'
import SideBar from '@/components/element/sideBar'
import StoreSelect from '@/components/storeListSelect'

export default {
  provide() {
    return {
      refresh: this.getFullGiftLists
    }
  },
  components: {
    ImportDialog,
    StoreSelect,
    SideBar
  },
  data () {
    return {
      listLoading:false,
      shopLoading:false,
      dealerLoading:false,
      search:'',
      search_dealer:'',
      search_role:'',
      save_search:'',
      showShop:false,
      showShopDealer:false,
      showRoles:false,
      shopsTitle:'',
      rolesTitle:'',
      storeVisible: false,
      relStores: [],
      relStoresList: [],
      relStoresListLength: 0,
      relDealerListLength: 0,
      relDealerList: [],
      bindRoles: [],
      setStatus: false,
      typeStores: null,
      form: {
        group_id: '',
        group_name: '',
        shop_ids: []
      },
      show_sideBar: false,
      groupLists: [],
      loading: false,
      params: {
        page: 1,
        pageSize: 10,
        search: '',
      },
      total_count: 0,
      list: [],
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'login_type'
    ])
  },
  methods: {
    handleNew(group_id) {
      this.show_sideBar = true
      if(group_id !== false){
        this.listLoading=true
      }
      this.resetData()
    },
    handleShopCancel() {
      this.showShop = false
    },
    handleDealerCancel() {
      this.showShopDealer = false
    },
    handleRolesCancel() {
      this.showRoles = false
    },
    resetData() {
      this.form = {
        group_name: '',
        group_id: '',
      }
      this.relStores =[]
      this.save_search =''
    },
    relStoresClick (type) {
      this.storeVisible = true
      this.setStatus = true
    },
    chooseStoreAction (data) {
      this.storeVisible = false
      this.form.shop_ids = []
      if (data === null || data.length <= 0) return
      console.log(data)
      this.relStores = data
    },
    closeStoreDialogAction (data) {
      this.storeVisible = false
    },
    handleCurrentChange (val) {
      this.params.page = val
      this.loading = false
      this.getGroupLists()
    },
    getGroupLists() {
      this.loading = true
      let filter = {
        ...this.params,
        status: this.activeName
      }
      getGroupLists(filter).then(response => {
        this.list = response.data.data.list
        console.log(this.list);
        this.total_count = Number(response.data.data.total_count)
        this.loading = false
      }).catch(error => {
        this.list = []
        this.total_count = 0
        this.loading = false
      })
    },
    save() {
      if (!this.form.group_name) {
        this.$message({type: 'error', message: '请填写分组名称'})
        return
      }
      if (this.relStores) {
        this.form.shop_ids = []
        this.relStores.forEach(item => {
          this.form.shop_ids.push(item.distributor_id)
        })
      }
      // 如果没有id，则表示为新增
      setGroup(this.form).then(res => {
        this.$message({type: 'success', message: '操作成功'})
        this.show_sideBar = false
        this.params.page = 1
        this.resetData()
        this.getGroupLists()
      })
    },
    deleteRow(index) {
      this.setStatus = false
      this.relStores.splice(index, 1)
    },
    deleteGroup(group_id) {
      this.$confirm('确认删除分组吗，删除后无法恢复！','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type:'warning'
      }).then(() => {
        let delData ={
          group_id:group_id,
        }
        deleteGroup(delData).then(res =>{
          this.$message({ type: 'success', message: '删除成功' })
          this.show_sideBar = false
          this.params.page = 1
          this.resetData()
          this.getGroupLists()
        })
      }).catche(() => {
        this.$message({
          type:'info',
          message:'已取消'
        })
      })

    },
    updateGroup(group_id){
      let queryData ={
        group_id:group_id,
      }
      this.listLoading=true
      this.handleNew(group_id)
      getGroupDetail(queryData).then(response => {
        this.relStores = response.data.data.shops
        this.form.group_name = response.data.data.group_name
        this.form.group_id = response.data.data.group_id
        this.listLoading= false
      })
      // this.listLoading= false
    },
    showGroupShops(group_id, group_name,dealer=false) {

      let queryData = {
        group_id: group_id,
      }
      this.shopsTitle = '【' + group_name + '】管理的店铺'
      if (!dealer) {
        this.shopLoading=true
        this.search = ''
        this.showShop = true
        this.showShopDealer = false
      } else {
        queryData.get_dealer = 1
        this.dealerLoading=true
        this.search_dealer = ''
        this.shopsTitle += '对应的经销商'
        this.showShopDealer = true
        this.showShop = false
      }

      getGroupDetail(queryData).then(response => {
        this.relStoresList = response.data.data.shops
        this.relStoresListLength = response.data.data.shops.length
        this.relDealerList = response.data.data.dealers
        this.relDealerListLength = response.data.data.dealers.length

        this.form.group_name = response.data.data.group_name
        this.form.group_id = response.data.data.group_id
        this.shopLoading=false
        this.dealerLoading=false
      })
    },
    showGroupRoles(group_id,group_name){
      let queryData ={
        group_id:group_id,
      }
      this.search_role=''
      this.rolesTitle = '【' + group_name + '】绑定的角色'
      this.showRoles = true
      getGroupBindRoles(queryData).then(response => {
        this.bindRoles = response.data.data.list
      })
    }
  },
  mounted () {
    this.getGroupLists()
  },
  watch: {
    '$route' () {
      this.getGroupLists()
    }
  },
}
</script>
<style scoped lang="scss">
.empty-box {
  font-size: 18px;
  color: #999;
  text-align: center;
  height: 300px;
  line-height: 50px;
}
</style>
