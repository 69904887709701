<template>
  <div v-loading="pageLoading" lock>
    <div v-for="(item, i) in cityList">
      <el-checkbox-group v-model="selected">
        <el-card class="box-card" style="margin-bottom: 10px" :loading="pageLoading">
          <div slot="header" class="clearfix">
            <span style="font-size: 14px;font-weight: bolder">{{item.title}}</span>
          </div>
          <div class="text item">
            <div style="display: inline-block">
              <el-checkbox
                v-for="(value,index) in item.items"
                :key="index"
                :label="value.id">
                {{value.label}}
              </el-checkbox>
            </div>
          </div>
        </el-card>
      </el-checkbox-group>
    </div>
    <div style="text-align: center; margin: 30px 0">
      <el-button type="primary" @click="saveHotCity">保存</el-button>
    </div>
  </div>
</template>
<script>
  import { getHotCity, setHotCity, getCityList } from '@/api/company'
  import cookie from "js-cookie";

  export default {
    data () {
      return {
        cityList: [],
        selected: [],
        pageLoading: true,
        params: [],
      }
    },
    computed: {
    },
    methods: {
      getCityList () {
        let query = {'first_charter': 1}
        getCityList(query).then(response => {
          this.cityList = response.data.data;
          if (response.data.data.length >0) {
            getHotCity().then(res => {
              this.selected = res.data.data;

              this.pageLoading = false
            }).catch(err => {
              this.pageLoading = false
            })
          } else {
            this.pageLoading = false
          }
        }).catch(error => {
          this.pageLoading = false
        })
      },
      saveHotCity () {
        let params = {}
        params.cities = JSON.parse(JSON.stringify(this.selected));
        setHotCity(params).then(response => {
          if (response.data.data.success === true) {
            this.$message({type:'success', 'message':'保存成功'});
          } else {
            this.$message({type:'error', 'message':'保存失败'});
          }
        })
      },
    },
    mounted () {
      this.getCityList();
      this.initWebSocket();
    }
  }
</script>
