const menu = {
  state: {
    menus: [],
    wheight: localStorage.getItem('wheight'),
    wwidth: localStorage.getItem('wwidth')
  },

  mutations: {
    setMenu: (state, menu) => {
      state.menus = menu
      // var key = 'menu'
      // var url = window.location.href
      // var pathPrefix = process.env.VUE_APP_PREFIXES
      // if (url.indexOf('shopadmin') !== -1) {
      //   key = key + pathPrefix + '/shopadmin'
      // } else {
      //   key = key + pathPrefix
      // }
      // localStorage.setItem(key,JSON.stringify(menu));
    },
    setWidth: (state, width) => {
      state.wwidth = width
      localStorage.setItem('wwidth', width);
    },
    setHeight: (state, height) => {
      state.wheight = height
      localStorage.setItem('wheight', height);
    }
  },

  actions: {
    setMenu ({commit}, menu) {
      commit('setMenu', menu)
    },
    setWidth ({commit}, width) {
      commit('setWidth', width)
    },
    setHeight ({commit}, height) {
      commit('setHeight', height)
    }
  },

  getters: {
    menus(state) {
      return state.menus;
    },
    wheight(state) {
      return state.wheight;
    },
    wwidth(state) {
      return state.wwidth;
    }
  },
};

export default menu;
