<style scoped lang="scss">
.goods-title {
  padding-bottom: 5px;
}
.goods-code {
  color: #888;
  font-size: 13px;
  i {
    cursor: pointer;
  }
}
</style>
<template>
  <div>
    <div v-if="$route.path.indexOf('_editor') === -1">
      <el-row class="filter-header" :gutter="20">
        <el-col>
          <el-button icon="el-icon-circle-plus" @click="handleNew()">新增协议</el-button>
          <el-tag type="danger" v-if="this.defaultInfo">当前使用的协议【标题:{{this.defaultInfo.title}} , ID:{{this.defaultInfo.id}}】</el-tag>
        </el-col>
      </el-row>
      <el-table :data="IupAgreementList" v-loading="loading" row-key="goods_id">
        <el-table-column prop="id" label="协议ID" width="80"></el-table-column>
        <el-table-column prop="title" label="协议标题"></el-table-column>
        <el-table-column prop="" label="协议图片">
          <template slot-scope="scope">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.image"
              :preview-src-list="[scope.row.image]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="" label="是否启用">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_default == 1 ? true : false" :disabled="(scope.row.is_default  == 1) ? true : false" active-color="#13ce66" inactive-color="#cccccc" @change="defaultSwitchChange(scope.row)"></el-switch>
          </template>
        </el-table-column>

        <el-table-column prop="create_time" label="创建时间" width="200">
          <template slot-scope="scope">
            <span>{{scope.row.create_time | datetime('yyyy-MM-dd hh:mm:ss')}}</span>
          </template>
        </el-table-column>
<!--        <el-table-column label="操作" width="110">
          <template slot-scope="scope">
                <span v-if="login_type == 'admin' || login_type == 'staff' || login_type == 'disti'">
                    <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button type="text" @click="deleteItemsAction(scope.$index, scope.row)" class="btn-gap">删除</el-button>
                </span>
          </template>
        </el-table-column>-->

      </el-table>
      <div class="content-center content-top-padded">
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :current-page.sync="params.page"
          :page-sizes="[10,20,50]"
          :total="total_count"
          :page-size="params.pageSize">
        </el-pagination>
      </div>
    </div>
    <router-view></router-view>
    <imgPicker :dialog-visible="imgDialog" :sc-status="isGetImage" @chooseImg="pickImg" @closeImgDialog="closeImgDialog"></imgPicker>
    <GoodsSelect :items-visible="itemVisible" :get-status="setItemStatus"  :is-spec=true :item-tier="itemTierAc" :rel-items-ids="relItemsIds" @chooseStore="chooseItemsAction" @closeStoreDialog="closeItemDialogAction"></GoodsSelect>
    <sideBar :visible.sync="show_sideBar" :title="'新增协议'">
      <el-form>
        <el-form-item label="协议标题">
          <el-input v-model="form.description" />
        </el-form-item>
        <el-form-item label="协议图片">
          <div class="frm-tips">只能上传jpg/png文件，且不超过2M</div>
          <div @click="handleImgPicker" class="upload-box">
            <img v-if="form.image" :src="form.image" class="avatar"/>
            <i v-else class="iconfont icon-camera avatar-uploader-icon"></i>
          </div>
        </el-form-item>
<!--        <el-form-item label="选择商品">-->
<!--          <el-button type="primary" class="el-icon-plus" @click="relItems"  size="mini">选择商品</el-button>-->
<!--          <el-table v-if="(form.recommend_items && form.recommend_items.length>0)" :data="form.recommend_items" style="line-height: normal" >-->
<!--            <el-table-column label="ID" prop="item_id" width="60"></el-table-column>-->
<!--            <el-table-column label="名称" prop="item_name"></el-table-column>-->
<!--            <el-table-column label="规格" prop="item_spec_desc"></el-table-column>-->
<!--            <el-table-column label="操作" width="50">-->
<!--              <template slot-scope="scope">-->
<!--                <i class="iconfont icon-trash-alt" @click="deleteItemRow(scope.$index, form.recommend_items)"></i>-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--          </el-table>-->
<!--        </el-form-item>-->
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </sideBar>
  </div>
</template>
<script>
import { getIupAgreement, setIupAgreement ,updateDefault} from '@/api/company'
import { mapGetters } from 'vuex'
import { getPopularizeSetting } from '@/api/promotions'
// import the component
import Treeselect from '@riophae/vue-treeselect'
import SideBar from '@/components/element/sideBar'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  getPedddingItemsList,delPedddingItemsList,savePedddingItemsList
} from '@/api/goods'
import GoodsSelect from '@/components/goodsSelect'
import imgPicker from '@/components/imageselect'

// 取选中地区的值
export default {
  components: {
    Treeselect,
    SideBar,
    imgPicker,
    GoodsSelect
  },
  props: ['getStatus'],
  provide() {
    return {
      refresh: this.getIupAgreement
    }
  },
  data () {
    return {
      login_type: 'default',
      itemVisible: false,
      IupAgreementList: [],
      defaultInfo: [],
      loading:false,
      total_count: 0,
      relItemsIds: [],
      imgDialog: false,
      isGetImage: false,
      setItemStatus: false,
      itemTierAc: [
        'acplus',
        'iup'
      ],
      form: {
        description: '',
        image: '',
        recommend_items:[]
      },
      params: {
        page: 1,
        pageSize: 20,
        title: '',
      },
      show_sideBar: false
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {

    init () {
      this.login_type = this.$store.getters.login_type
      if (this.$route.path.split('/')[2] === 'godsphysicalkj') {
        console.log('跨境商品列表')
        this.params.type = 1
      } else {
        console.log('普通商品列表')
        this.params.type = 0
      }
      this.login_type = this.$store.getters.login_type
      if (this.$route.query.category) {
        this.params.category = this.$route.query.category
      }
      this.params.main_cat_id = this.$route.query.main_cat_id

      getPopularizeSetting().then(response => {
        this.popularizeSetting = response.data.data
      })
      if (this.$route.query.tab) {
        // this.activeName = this.$route.query.tab
        this.params.is_warning = true
      }
      this.getIupAgreement()
    },
    goodsSearch () {
      this.params.page = 1
      this.getIupAgreement()
    },
    handleImgPicker () {
      this.imgDialog = true
      this.isGetImage = true
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getIupAgreement()
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getIupAgreement()
    },
    pickImg (data) {
      this.form.image = data.url
      this.imgDialog = false
    },
    closeImgDialog () {
      this.imgDialog = false
      this.isGetImage = false
    },
    onCopy () {
      this.$notify.success({
        message: '复制成功',
        showClose: true
      });
    },
    closeItemDialogAction () {
      this.itemVisible = false
    },
    handleEdit(data) {
      this.show_sideBar = true
      this.form = {
        item_id: data.item_id,
        description: data.description,
        image: data.image,
      }
      let recommendary =[]
      let info ={
        item_id:data.item_id,
        item_name:data.item_name,
        item_spec_desc:data.item_spec_desc
      }
      recommendary.push(info)
      this.form.recommend_items =recommendary
    },
    relItems () {
      this.itemVisible = true
      this.setItemStatus = true

      // this.relItemsIds = this.form.recommend_items
    },
    defaultSwitchChange (row) {
      let params = {
        id: row.id,
        set_default: true
      }
      updateDefault(params).then(response => {
        if (response.data.data.success) {
          this.$message({
            message: "修改成功",
            type: "success"
          });
          this.getIupAgreement();
        }
      })
    },
    chooseItemsAction (data) {
      this.itemVisible = false
      this.relItemsIds = data
      /* if (this.form.recommend_items.length > 0 && data.length <= 0) {
         data = this.form.recommend_items
       } */
      if (data === null || data.length <= 0) return
      if (data.length > 1) {
        this.$message({message: '只能选择一个商品', type: 'error'})
        return
      }
      let arr = []
      data.forEach((item, index) => {
        let newData = {
          item_id: item.item_id,
          itemId: item.item_id,
          item_name: item.item_name,
          itemName: item.item_name,
          item_spec_desc:item.item_spec_desc

          // sort: item.sort,
        }
        arr.push(newData)
      })
      this.form.recommend_items = arr
    },
    save() {
      // 如果没有id，则表示为新增
      setIupAgreement(this.form).then(res =>{
        this.$message({ type: 'success', message: '操作成功' })
        this.show_sideBar = false
        this.params.page = 1
        this.resetData()
        this.getIupAgreement()
      })
    },
    resetData() {
      this.form = {
        item_id: '',
        description:'',
        image: '',
      }
    },
    deleteItemRow (index, rows) {
      rows.splice(index, 1)
      this.form.recommend_items = rows
      this.setItemStatus = false
      this.relItemsIds.splice(index, 1)
    },
    deleteItemsAction (index, row) {
      this.$confirm('此操作将删除该商品附加信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delPedddingItemsList(row).then(response => {
          this.IupAgreementList.splice(index, 1)
          this.$message({
            message: '删除信息成功',
            type: 'success',
            duration: 2 * 1000
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    handleNew() {
      this.show_sideBar = true
      this.resetData()
    },
    getIupAgreement () {
      this.loading = true
      let params = JSON.parse(JSON.stringify(this.params))
      if (params.is_gift == 'all') {
        this.$delete(params, 'is_gift')
      }
      getIupAgreement(params).then(response => {
        this.IupAgreementList = response.data.data.list
        this.total_count = response.data.data.total_count
        this.defaultInfo = response.data.data.default
        this.loading = false
      })
    }
  },
  mounted () {
    this.init()
  },

  destroyed () {
    console.log(111)
  },
  watch: {
    '$route' (to, from) {
      this.init()
    },
    getStatus (val) {
      if (val) {
        this.getIupAgreement()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 90%;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 10px;
  img {
    width: 90%;
  }
}
.row-bg {
  padding: 10px 20px;
  background-color: #f9fafc;
}
.service-label .el-checkbox:first-child{
  margin-left: 15px;
}
.service-label .el-input:first-child{
  margin-left: 15px;
}
.grid-detail {
  max-height: 300px;
  overflow-y: scroll;
  margin-bottom: 20px;
}
.el-carousel {
  width: 375px;
}
.tag-users {
  padding-bottom: 10px;
  line-height: 1.2;
  .icon-user-circle1 {
    margin-right: 10px;
    font-size: 24px;
    color: #ccc;
  }
}
.selected-tags {
  padding-top: 15px;
  margin-bottom: 15px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  .label {
    margin-bottom: 15px;
  }
  .el-tag {
    margin-right: 10px;
    margin-bottom: 15px;
  }
}
.tag-item {
  cursor: pointer;
  color: #666;
  border-color: #dadadd;
  margin-right: 10px;
  margin-top: 10px;
}
.copy-btn {
  position: relative;
  [class^="copy-link"] {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
  }
}
</style>
<style lang="scss">
.grid-detail {
  table, .detail-content-wrap, .detail-content-item {
    width: 100%!important;
  }
  img {
    width: 100%;
  }
}
.grid-attribute {
  table {
    width: 100%!important;
  }
}
.el-drawer__body {
  overflow: auto;
}
</style>
