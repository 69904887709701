<template>
  <el-form ref="form" label-width="100px">
    <el-form-item label="公众账号ID">
      <el-input v-model="form.app_id" style="width:300px"></el-input>
      <br />
      <span class="frm-tips">微信支付分配的公众账号ID（企业号corpid即为此appId）</span>
    </el-form-item>
    <el-form-item label="APP应用ID">
      <el-input v-model="form.app_app_id" style="width:300px"></el-input>
      <br />
      <span class="frm-tips">微信开放平台审核通过的应用APPID（请登录open.weixin.qq.com查看，注意与公众号的APPID不同）</span>
    </el-form-item>
    <el-form-item label="商户号">
      <el-input v-model="form.merchant_id" style="width:300px"></el-input>
    </el-form-item>
    <el-form-item label="API密钥">
      <el-input v-model="form.key" style="width:300px"></el-input>
    </el-form-item>
    <el-form-item label="是否特约商户">
      <el-switch v-model="form.is_servicer" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
      <span class="frm-tips">如是特约商户，请联系您的服务商获取如下两个参数</span>
    </el-form-item>
    <el-form-item label="服务商APPID">
      <el-input v-model="form.servicer_app_id" style="width:300px"></el-input>
    </el-form-item>
    <el-form-item label="服务商商户号">
      <el-input v-model="form.servicer_merchant_id" style="width:300px"></el-input>
    </el-form-item>
    <el-form-item label="商户证书">
      <span v-if="form.cert_name"> {{form.cert_name}}</span>
      <el-upload class="" action="" :on-change="certHandleChange" :auto-upload="false">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <span class="frm-tips">微信商户平台(pay.weixin.qq.com)-->账户设置-->API安全-->证书下载 apiclient_cert.pem</span>
    </el-form-item>
    <el-form-item label="商户证书秘钥">
      <span v-if="form.cert_key_url"> {{form.cert_key_name}}</span>
      <el-upload class="" action="" :on-change="keyHandleChange" :auto-upload="false">
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>
      <span class="frm-tips">微信商户平台(pay.weixin.qq.com)-->账户设置-->API安全-->证书下载 apiclient_key.pem</span>
    </el-form-item>
    <el-form-item label="是否启用">
      <el-switch v-model="form.is_open" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
    </el-form-item>
    <div class="section-footer with-border content-center">
      <el-button type="primary" v-loading="loading" @click="onSubmit">保存</el-button>
    </div>
  </el-form>
</template>
<script>
  import { setPaymentSetting, getPaymentSetting } from '../../../../api/trade'
  export default {
    data () {
      return {
        activeName: 'wxpay',
        loading: false,
        teegonForm: {
          app_key: '',
          client_secret: '',
          is_open: '',
        },
        form: {
          app_id: '',
          app_app_id: '',
          merchant_id: '',
          key: '',
          is_servicer: false,
          servicer_merchant_id: '',
          servicer_app_id: '',
          cert: '',
          cert_key: '',
          is_open: true
        }
      }
    },
    methods: {
      handleClick () {
        this.getConfig()
      },
      certHandleChange (file, fileList) {
        this.form.cert = file.raw
      },
      keyHandleChange (file, fileList) {
        this.form.cert_key = file.raw
      },
      getConfig () {
        let query = {}
        if (this.activeName == 'wxpay') {
          query = {pay_type: 'wxpay'}
          getPaymentSetting(query).then(response => {
            this.form = response.data.data
            this.form.is_servicer = response.data.data.is_servicer == 'true' ? true : false
            this.form.is_open = response.data.data.is_open == 'true' ? true : false
          })
        } else {
          query = {pay_type: 'teegon'}
          getPaymentSetting(query).then(response => {
            this.teegonForm = response.data.data
          })
        }
      },
      onSubmit () {
        this.loading = true
        let query = {}
        if (this.activeName == 'wxpay') {
          query = {
            isUploadFile: true,
            pay_type: 'wxpay',
            app_id: this.form.app_id,
            app_app_id: this.form.app_app_id,
            merchant_id: this.form.merchant_id,
            key: this.form.key,
            is_servicer: this.form.is_servicer,
            servicer_merchant_id: this.form.servicer_merchant_id,
            servicer_app_id: this.form.servicer_app_id,
            is_open: this.form.is_open,
            cert: this.form.cert,
            cert_key: this.form.cert_key
          }
        } else {
          query = {
            pay_type: 'teegon',
            config: this.teegonForm
          }
        }
        setPaymentSetting(query).then(response => {
          this.$message({
            type: 'success',
            message: '保存成功'
          })
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })
      }
    },
    mounted () {
      this.getConfig()
    }
  }
</script>
<style scoped lang="scss">
.el-row {
  margin-bottom: 10px;
  .frm-tips {
    color: #FF0000;
  }
}
</style>
