<template>
  <el-form ref="form" label-width="100px">
    <el-form-item label="merchantID">
      <el-input v-model="form.merchant_id" style="width:300px"></el-input>
      <br />
    </el-form-item>

    <el-form-item label="APP ID">
      <el-input v-model="form.app_id" style="width:300px"></el-input>
      <br />
    </el-form-item>

    <el-form-item label="APP Secret">
      <el-input v-model="form.app_secret" style="width:300px"></el-input>
      <br />
    </el-form-item>

     <el-input v-model="form.id" type="hidden"></el-input>

    <div class="section-footer with-border content-center">
      <el-button type="primary" v-loading="loading" @click="onSubmit">保存</el-button>
    </div>
  </el-form>
</template>
<script>
  import { setSetting, getSetting } from '../../../../api/dataAnalysis'
  export default {
    data () {
      return {
        activeName: 'youshu',
        loading: false,
        form: {
            id: '',
            merchant_id: '',
            app_id: '',
            app_secret: ''
        }
      }
    },
    methods: {
      handleClick () {
        this.getConfig()
      },
      getConfig () {
        getSetting().then(response => {
            if (response.status == 200) {
                this.form = response.data.data
            }
        })
      },
      onSubmit () {
        this.loading = true
        let query = {
            id: this.form.id,
            merchant_id: this.form.merchant_id,
            app_id: this.form.app_id,
            app_secret: this.form.app_secret,
        }
        setSetting(query).then(response => {
          this.$message({
            type: 'success',
            message: '保存成功'
          })
          this.getConfig()
          this.loading = false
        })
          .catch(error => {
            this.loading = false
          })
      }
    },
    mounted () {
      this.getConfig()
    }
  }
</script>
<style scoped lang="scss">
  .el-row {
    margin-bottom: 10px;
    .frm-tips {
      color: #FF0000;
    }
  }
</style>
