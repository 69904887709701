const user = {
  state: {
    token: '',
    exp: '',
    name: '',
    is_authorizer: '',
    license_authorize: '',
    route_app: '',
    wxapp_id: '',
    template_name: '',
    login_type: '',
    admin_type: '',
    nick_name: '',
    avatar: '',
    shopid: '',
    golder_color: ''
  },

  mutations: {
    setShopId: (state, id) => {
      state.shopid = id
    },
    setToken: (state, token) => {
      state.token = token
    },
    setTokenExp: (state, exp) => {
      state.exp = exp
    },
    setUserName: (state, name) => {
      state.name = name
    },
    setNickName: (state, nickName) => {
      state.nick_name = nickName
    },
    setAvatar: (state, avatar) => {
      state.avatar = avatar
    },
    setAuthorizer: (state, isAuthorizer) => {
      state.is_authorizerr = isAuthorizer
    },
    setLicenseAuthorize: (state, isLicenseValid) => {
      state.license_authorize = isLicenseValid
    },
    setWxappId: (state, wxappId) => {
      state.wxapp_id = wxappId
    },
    setLoginType: (state, loginType) => {
      state.login_type = loginType
    },
    setAdminType: (state, adminType) => {
      state.admin_type = adminType
    },
    setCompanyType: (state, companyType) => {
      state.company_type = companyType
    },
    setTemplateName: (state, templateName) => {
      state.template_name = templateName
    },
    setGolderColor: (state, goldercolor) => {
      state.golder_color = goldercolor
    }
  },

  actions: {
    setAuthorizer({ commit }, is_authorizer) {
      commit('setAuthorizer', is_authorizer)
    },
    setLicenseAuthorize({ commit }, license_authorize) {
      commit('setLicenseAuthorize', license_authorize)
    },
    // 保存token
    setToken({ commit }, token) {
      commit('setToken', token)
      // 解析token中的user信息，并且保存
      let tokenArray = token.split('.')
      let user = atob(tokenArray[1])
      user = JSON.parse(user)
      console.log(user, '------user------')
      commit('setTokenExp', user.exp)
      commit('setUserName', user.mobile)
      commit('setAuthorizer', user.is_authorizer)
      commit('setLicenseAuthorize', user.license_authorize)
    },
    // 删除token
    unsetToken({ commit }, token) {
      commit('setToken', '')
      commit('setTokenExp', '')
      commit('setUserName', '')
      commit('setAuthorizer', '')
      commit('setLicenseAuthorize', '')
      commit('setLoginType', '')
    },
    setUser({ commit }, userInfo) {
      commit('setNickName', userInfo.username)
      commit('setAvatar', userInfo.head_portrait)
      commit('setAdminType', userInfo.admin_type)
    },
    setWxappId({ commit }, wxapp_id) {
      commit('setWxappId', wxapp_id)
    },

    setTemplateName({ commit }, template_name) {
      commit('setTemplateName', template_name)
    },
    setLoginType({ commit }, login_type) {
      commit('setLoginType', login_type)
    },
    setAdminType({ commit }, admin_type) {
      commit('setAdminType', admin_type)
    },
    setShopId({ commit }, id) {
      commit('setShopId', id)
    },
    setGolderColor({ commit }, golder_color) {
      commit('setGolderColor', golder_color)
    },
  }
}

export default user
