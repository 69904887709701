<template>
  <div class="section-white">
    <div class="section-box view-flex view-flex-vertical view-flex-center view-flex-middle">
      <div class="slogn">
        <div class="logo-box">
          <img :src="logoIcon" alt="">
        </div>
        <div v-if="companyBrand == '超新星'"><h3>超新星因你而更好</h3></div>
      </div>
      <div class="content-center" v-if="loginType !='distributor'">
        <el-input v-model="active_code" placeholder="请输入激活码" style="width: 300px;"></el-input>
        <p class="desc">购买或续费产品请联系：400-9213-522 &nbsp;&nbsp; QQ: 800058282</p>
      </div>
      <div class="content-center" v-if="loginType =='distributor'">
        <p class="desc">您的授权已过期，请联系平台管理员处理授权</p>
      </div>
      <div class="active-opr" >
        <el-button type="primary" @click="activetionAction" v-if="loginType !='distributor'">激活</el-button>
        <el-button type="default" @click="closeDialog">返回登录</el-button>
      </div>
    </div>
    <el-dialog title="提示" :visible="dialogVisible" :close-on-click-modal='false' :before-close="closeDialog">
      <div class="brand">
        <div class="logo-box">
          <img :src="logoIcon" alt="">
        </div>
        <span>{{resultTxt}}</span>
      </div>
      <p class="frm-tips content-center" style="margin-top: 20px;">此资源包到期时间为&nbsp; <span>{{expired_at | datetime('yyyy-MM-dd hh:mm:ss')}}</span></p>
      <div slot="footer" class="dialog-footer content-center active-opr">
        <el-button type="primary" @click="closeDialog">{{btnTxt}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { activate, getActivateInfo } from '@/api/company'
  export default {
    data () {
      return {
        activateInfo: {},
        logoIcon: '',
        active_code: '',
        expired_at: '',
        dialogVisible: false,
        resultTxt: '恭喜您激活成功！',
        btnTxt: '确 定',
        loginType: 'default',
      }
    },
    methods: {
      activetionAction () {
        if (this.active_code == "") {
          this.$message({message:'请输入激活码', type: 'error'})
          return
        }
        let params = {
          active_code: this.active_code
        }
        activate(params).then(res => {
          if(res.data.data) {
            this.$store.dispatch('setLicenseValid', true)
            this.expired_at = res.data.data.expired_at
            this.dialogVisible = true
          }
        })
      },
      closeDialog () {
        const loginPath = this.path_prefixes ? `/${this.path_prefixes}/login` : '/login'
        this.dialogVisible = false
        this.$store.dispatch('unsetToken').then(() => {
          this.$router.push({path: loginPath})
        })
      },
      getActivateInfo () {
        getActivateInfo().then(res => {
          this.activateInfo = res.data.data
          if(res.data.data.is_valid) {
            this.activateInfo = res.data.data
          } else {
            this.$router.push({path: this.matchInternalRoute('assetaccountactivation')})
          }
        })
      }
    },
    mounted() {
      this.loginType = this.$store.getters.login_type
      this.logoIcon = require('../../../../../static/img/'+this.companyBrandImg+'/logo.svg')
    }
  }
</script>

<style scoped lang="scss">
  .section-box {
    height: 600px;
    text-align: center;
    > div {
      margin-bottom: 20px;
    }
  }
  .slogn {
    text-align: center;
    font-size: 0;
    .logo-box {
      margin: 10px auto;
      width: 200px;
      margin-bottom: 20px;
    }
    img {
      width: 100%;
    }
    h3, span {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      font-size: 22px;
    }
  }
  .desc {
    margin-top: 10px;
    color: #999;
  }
</style>
<style type="text/css">
  .active-opr .el-button span {
    padding: 0 20px;
  }
</style>
